import React from 'react'

import {soluciones} from '../../data/serviceDetailMoldaje'

import Links from '../Links'
import General from '../General'
import Gallery from '../Gallery'

import Disponibilidad from '../Disponibilidad'

import {moldaje} from  '../../data/services'

import Container from 'react-bootstrap/Container'


function Soportacion(){
  
  let title ="SOLUCIONES ESPECIALES"
  let text='Sistemas para soporte de losa.'
  let detail = {
    subtitle: 'Soluciones Especiales en Moldajes',
    parragraph: 'Contamos con una amplia gama de productos tanto para la venta como para el arriendo,'
  }

  return(
    <div >
        <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgSoluciones' detail={''} list={''}  />
        
    <Container fluid className='mt-n10' >
    <Links data = {moldaje}  />    
    <h1 className="text-center red ">{detail.subtitle}</h1>

    <h5 className="text-center">{detail.parragraph}</h5>
    </Container>
        
        
        <Gallery projects={soluciones} />

        <Disponibilidad pais={'Chile.'}/>
    </div>
  )
}

export default Soportacion
