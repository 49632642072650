import React from 'react';
import { Link } from 'react-router-dom';

import General from '../General';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faUserCircle,
  faUsers,
  faHome,
  faUserCheck,
  faEdit,
  faImage,
} from '@fortawesome/free-solid-svg-icons';

function About() {
  let title = 'LA EMPRESA';
  let text =
    'La trayectoria de Mecanotubo asegura un óptimo resultado en cualquier proyecto que usted y su empresa se propongan desarrollar.';

  let detail = {
    subtitle:
      'Mecanotubo Chile nace el año 1998, persiguiendo satisfacer parte de la demanda cada vez mayor en Chile de sistemas de encofrado. ',
    parragraph:
      ' Somos una empresa que tiene una presencia continuada en el sector de la construcción, gracias a su evolución permanente en la mejora de los medios y de la alta tecnología que ponemos a disposición de nuestros clientes, con quienes colaboramos estrechamente en la selección de la solución idónea que permita la construcción de los pasos de ejecución previstos con un costo mínimo, empleando medios seguros y fiables. La empresa cuenta con una amplia gama de productos, entre los que se encuentran: encofrado de muros, sistemas para soporte de losa, entibación de zanjas y colectores, y soluciones especiales de hormigonado.',
  };
  let menu = [
    { id: 1, name: 'Nuestras Obras', link: '/nuestrasobras' },
    // { id: 2, name: 'Nuestros Clientes', link: '/clientes' },
    { id: 3, name: ' Nuestro Equipo', link: '/team' },
    { id: 5, name: 'Oficinas', link: '/office' },
    { id: 4, name: 'Asesorías', link: '/asesorias' },
    { id: 6, name: 'Contáctenos', link: '/contact' },
    { id: 7, name: 'Galería', link: '/gallery' },
  ];

  const list = menu.map((ele) => {
    let photo = '';
    switch (ele.id) {
      case 1:
        photo = (
          <FontAwesomeIcon icon={faBuilding} size="sm" className="redLink" />
        );
        break;
      case 2:
        photo = (
          <FontAwesomeIcon icon={faUserCircle} size="sm" className="redLink" />
        );
        break;
      case 3:
        photo = (
          <FontAwesomeIcon icon={faUsers} size="sm" className="redLink" />
        );
        break;
      case 4:
        photo = (
          <FontAwesomeIcon icon={faUserCheck} size="sm" className="redLink" />
        );
        break;
      case 5:
        photo = <FontAwesomeIcon icon={faHome} size="sm" className="redLink" />;
        break;
      case 6:
        photo = <FontAwesomeIcon icon={faEdit} size="sm" className="redLink" />;
        break;
      case 7:
        photo = (
          <FontAwesomeIcon icon={faImage} size="sm" className="redLink" />
        );
        break;
      default:
        photo = '';
        break;
    }
    return (
      <div className="p-1 px-1 w-50">
        <div className="pt-2" key={ele.id}>
          {photo}
          <Link className="text-dark font-weight-bold" to={ele.link}>
            {' '}
            {ele.name}
          </Link>
        </div>
      </div>
    );
  });

  return (
    <General
      title={title}
      text={text}
      imgStyle="jumbotronGeneral imgAbout"
      detail={detail}
      list={list}
    />
  );
}

export default About;
