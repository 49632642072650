import React from 'react'

function Map(props){
  
 
  
  // console.log('props in map', props)
  
  
  
  return(  
    
    
    
    <div>
    <iframe 
      src= {props.map}
      width="600" height="450" frameborder="0" allowfullscreen="" title="map"></iframe>
  </div>
)


}


export default Map

