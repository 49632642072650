import React from 'react'
import {Link} from 'react-router-dom'

import {geotecnia} from '../../data/services'
import General from '../General'
import Disponibilidad from '../Disponibilidad'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import {  faSteamSquare } from '@fortawesome/free-brands-svg-icons'

function Entibaciones(){
  
  let title = 'GEOTECNIA'
  let text = 'Abarcamos las más variadas soluciones para ésta área de la ingeniería que se encarga de los suelos como soporte de las edificaciones y obras de infraestructura.'
  let subtitulo = 'Dentro de sus principales ventajas se encuentran:'
  let subtituloList2 = 'Los distintos sistemas con que contamos son:'

  
  let menuDetail = [
    {id:2, text: 'Mejora en los tiempos y rendimientos. '},
    {id:3, text: 'Aplicación de tecnología.'},
    {id:4,text:  'Mayor seguridad en el trabajo. '},
    {id:5, text: 'Menor uso de mano de obra.' }
  ]
  
  const detail = menuDetail.map(ele =>{
    return (
      <h5 key={ele.id}>
       <FontAwesomeIcon icon={faCheckSquare} size="sm" className="red" />
       {ele.text}
      </h5>
    )
  })
  
  let menu = geotecnia.list
    
  // let menu =[
  //   {id:1, name: 'Hincado y retiro de Tablestacas', link:"/hincado"},
  //   {id:2, name: 'Descabezado de Pilotes', link:"/descabezado"},
  //   {id:3, name: 'Fresado de muros y elementos de hormigón,', link:'/fesado'},
  //   {id:4, name: 'Perforaciones en hormigón (verticales y horizontales)', link:'/perforaciones'},
  //   {id:5, name: 'Demolición de hormigón (picado, fresado y cizalla)', link:'/demolicion'},
  // ]
  
  const list = menu.map(ele =>{
    return (
      <div className="p-1 px-1 w-100"> 
      <div className="pt-2" key={ele.id}> 
       <FontAwesomeIcon icon={faSteamSquare} size="sm" className="redLink" />
       <Link to={ele.link} className="text-dark font-weight-bold" > {ele.name}</Link>
     </div></div>
    )
  })
  
  
   
  return(
    <div>
        <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgGeotecnia' 
        detail = {detail} 
        subtitulo={subtitulo} 
        list = {list} 
        subtituloList2={subtituloList2}
        />
        
        
        <Disponibilidad pais={'Chile.'}/>
    </div>
  )
}

export default Entibaciones
