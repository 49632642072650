import React from 'react'


import {encofrado} from '../../data/serviceDetailMoldaje'


import Links from '../Links'
import General from '../General'
import Gallery from '../Gallery'

import Disponibilidad from '../Disponibilidad'

import {moldaje} from  '../../data/services'

import Container from 'react-bootstrap/Container'


function Encofrado(){
  
  let title ="ENCOFRADO"
  let text='Un sistema de moldaje es un conjunto de elementos dispuestos de forma tal que cumple con la función de moldear el hormigón fresco a la forma y tamaño especificado, controlando su posición y alineamiento dentro de las tolerancias exigidas.'
  let detail = {
    subtitle: 'Moldajes Encofrados',
    parragraph: 'Moldaje de hormigón fresco a la forma y tamaño especificado.'
  }

  return(
    <div >
     <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgEncofrado' detail={''} list={''}  />
        
    <Container fluid className='mt-n10' >
    <Links data = {moldaje}  />    
    <h1 className="text-center red ">{detail.subtitle}</h1>

    <h5 className="text-center">{detail.parragraph}</h5>
    </Container>
        
        
        <Gallery projects={encofrado} />

        <Disponibilidad pais={'Chile.'}/>
    </div>
  )
}

export default Encofrado
