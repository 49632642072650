import React from 'react'

function Disponibilidad(props){
  return(
    <div>
      <h5 className="text-center redDark font-weight-bold disp"> 
          Servicio Disponible en {props.pais}
      </h5>
    </div>
  )
}

export default Disponibilidad
