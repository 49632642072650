import React from 'react'
import {team} from './../../data/team'

import General from '../General'


import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPuzzlePiece, faTint, faUsersCog } from '@fortawesome/free-solid-svg-icons'

function Team(){
  
  let title = 'EQUIPO'
  let text = 'Los profesionales que integran el grupo Mecanotubo se identifican con la frase: “Diseña el futuro, Actúa en el presente”. Trabajamos para cumplir los objetivos de la empresa que también son los nuestros, la única forma de asegurar el éxito es saber todo lo que puedas, de lo que haces.'
  let subtitulo = 'Nuestros Valores'
   
  
  const boxes =  team.map(ele => {
    let photo = ''
    switch (ele.id){
      case 1:
        photo = <FontAwesomeIcon icon={faPuzzlePiece} size="4x" />
        break;
      case 2:
          photo =   <FontAwesomeIcon icon={faTint} size="4x" />
          break;
      case 3:
          photo =   <FontAwesomeIcon icon={faUsersCog} size="4x" />
          break;
     
        default:
            photo =''
      break;
    }
  return (
    <Col xlg={4} lg={4} xs={12} md={6} sm={12} className="pb-3 card-group">     
    <Card className="border-secondary " >
          <div className="text-center text-secondary m-3"  key={ele.id}>   
              {photo}
          </div> 
          <Card.Body>
            <Card.Title className="redDark">  {ele.name} </Card.Title>
            <Card.Text>
            {ele.description} 
            </Card.Text>
          </Card.Body>
    </Card>
    </Col>

    )}
  )  
  
  return(

    <div>
    <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgTeam'
            detail = {''} list={''} subtitulo={subtitulo} />

    <Container fluid >
        <Row className="px-1 py-3 m-2">
        
        {boxes}
        </Row>
      </Container>
      
      
    </div>
  )
}

export default Team
