import React from 'react'
import {Link} from 'react-router-dom'

import {entibaciones} from '../../data/services'


import General from '../General'
import Disponibilidad from '../Disponibilidad'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faTh} from '@fortawesome/free-solid-svg-icons'

function Entibaciones(){
  
  let title = 'ENTIBACIONES'
  let text = 'Son un tipo de estructura de contención provisional muy flexible, empleada para asegurar los taludes verticales en excavaciones.'
  let subtitulo = 'Dentro de sus principales ventajas se encuentran:'
  let subtituloList2 = 'Los distintos sistemas con que contamos son:'

  
  let menuDetail = [
    {id:1, text: ' La inversión del arriendo o compra se amortiza rápidamente.'},
    {id:2, text: ' La obra avanza a alta velocidad.'},
    {id:3, text: ' La excavadora se usa en un 100%.'},
    {id:4,text:  ' Son muy versátiles y se puede adaptar a diferentes anchos y profundidades de zanja. '},
    {id:5, text: ' Generan importantes ahorros en mano de obra, ya que, utilizando métodos artesanales se necesitan hasta cuatro obreros especializados, sin embargo con entibación metálica generalmente basta con uno o dos.' }
  ]
  
  const detail = menuDetail.map(ele =>{
    return (
      <h5 key={ele.id}>
       <FontAwesomeIcon icon={faCheckSquare} size="sm" className="red" />
        {ele.text}
      </h5>
    )
  })

  let menu = entibaciones.list
  
  const list = menu.map(ele =>{
    return (
      <div className="p-1 px-1 w-100"> 
      <div className="pt-2" key={ele.id}> 
       <FontAwesomeIcon icon={faTh} size="sm" className="redLink" />
       <Link to={ele.link} className="text-dark font-weight-bold" > {ele.name}</Link>
     </div>
     </div>
    )
  })
  
   
  return(
    <div>
        <General title ={title} text = {text} 
        imgStyle = 'jumbotronGeneral imgEntibacion' 
        detail = {detail} 
        subtitulo={subtitulo} 
        list={list} 
        subtituloList2={subtituloList2}
        />
        
        <Disponibilidad pais={'Chile y Perú.'}/>
    </div>
  )
}

export default Entibaciones
