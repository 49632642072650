import React  from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import HomeBtns from './HomeBtns'


function ControlledCarousel ()  {
  
   
  return (
        <Carousel>
          
        {/* 1ST SLIDE */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/assets/img/01.jpg"
            // src="https://dummyimage.com/800x400/000/fff"
            alt="First slide"
          />
        <div className="bs-slider-overlay"></div>
        
          <Carousel.Caption  className="titleAlign">
          <Jumbotron fluid className="bg-transparent">
            <h1 className="title text-left">MECANOTUBO</h1>
            <h2 className = "text-left">Las Mejores Soluciones y Experiencia en Servicio </h2>
          
            <Row className="d-none d-lg-block">
            <HomeBtns />
            </Row>
          </Jumbotron>
          </Carousel.Caption>
        </Carousel.Item>
        
        
            {/* 2ND SLIDE */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/assets/img/02.jpg"
            alt="Third slide"
          />
          <div className="bs-slider-overlay"></div> 
          
          <Carousel.Caption  className="titleAlign">
          <Jumbotron  className="bg-transparent fluid">
            <Container>
                <h1 className="title" >SOLUCIONES A SU MEDIDA</h1>
                <h2>Respuestas rápidas y en terreno</h2>
                <Row className="d-none d-lg-block">
                <HomeBtns position={'justify-content-center'} />
              </Row>
            </Container>
          </Jumbotron>
          </Carousel.Caption>
        </Carousel.Item>
        
          {/* 3ER SLIDE */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/assets/img/03.jpg"
            alt="Third slide"
          />
        <div className="bs-slider-overlay"></div>
        <Carousel.Caption  className="titleAlign">
          <Jumbotron  className="bg-transparent fluid">
            <Container>
                <h1 className="title text-right" >CALIDAD, SOPORTE</h1>
                <h2 className="text-right">Asesoría Profesional </h2>
              
                <Row className="d-none d-lg-block">
                <HomeBtns position={'justify-content-end'} />
            </Row>
            
            </Container>
          </Jumbotron>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )
  
};

export default ControlledCarousel
