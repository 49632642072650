import React from 'react';
import Image from 'react-bootstrap/Image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';

export function Chile() {
  return (
    <div>
      <h5>
        {' '}
        <Image className="flag" src="/assets/img/flagChile.png" /> Mecanotubo
        Chile
      </h5>
      <ul className="list-unstyled text-small">
        <li className="text-muted">
          <FontAwesomeIcon icon={faPhone} size="sm" />
          <a className="text-muted" href="tel:+56225966390">
            {' '}
            +56 22596 6390 
          </a>
          <a className="text-muted" href="tel:+56982890326">
            {' '}
            +56 9 82890326
          </a>
        </li>
        <li className="text-muted">
          <a
            className="text-muted"
            href="https://goo.gl/maps/7vq4YshEogUYo2Xy9"
            target="_blank"
            rel="noopener noreferrer"
            alt="Map"
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" /> Panamericana
            Norte Nº23580 Lampa Santiago
          </a>
        </li>
        <li className="text-muted">
          <FontAwesomeIcon icon={faEnvelope} size="sm" />
          <a className="text-muted" href="mailto:mecanotubo@mecanotubo.cl">
            {' '}
            mecanotubo@mecanotubo.cl
          </a>
        </li>

        <li className="text-muted">
          <FontAwesomeIcon icon={faGlobe} size="sm" />
          <a
            className="text-secondary"
            href="http://www.mecanotubo.cl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            mecanotubo.cl{' '}
          </a>
        </li>
      </ul>
    </div>
  );
}

export function Peru() {
  return (
    <div>
      <h5>
        {' '}
        <Image className="flag" src="/assets/img/flagPeru.jpg" /> Mecanotubo
        Perú
      </h5>
      <ul className="list-unstyled text-small">
        <li className="text-muted">
          <FontAwesomeIcon icon={faPhone} size="sm" />

          <a className="text-muted" href="tel:+51960228039">
            {' '}
            +51 96022 8039 |{' '}
          </a>
          <a className="text-muted" href="tel:+51960228035">
            {' '}
            +51 96022 8035 |{' '}
          </a>
          <a className="text-muted" href="tel:+51972503910">
            {' '}
            +51 97250 3910{' '}
          </a>
        </li>
        <li className="text-muted">
          <a
            className="text-muted"
            href="https://goo.gl/maps/z9LrTyh8uHUsfTMK7"
            target="_blank"
            rel="noopener noreferrer"
            alt="Map"
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" /> Av. Alfredo
            Benavides 768 Of. 301 Miraflores Lima
          </a>
        </li>
        <li className="text-muted">
          <FontAwesomeIcon icon={faEnvelope} size="sm" />
          <a className="text-muted" href="mailto:mecanotubo@mecanotubo.cl">
            {' '}
            mecanotubo@mecanotubo.cl
          </a>
        </li>
        <li className="text-muted">
          {' '}
          <FontAwesomeIcon icon={faGlobe} size="sm" />
          <a
            className="text-secondary"
            href="http://www.mecanotubo.pe/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            mecanotubo.pe{' '}
          </a>
        </li>
      </ul>
    </div>
  );
}
