import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Chile, Peru} from './ContactInfo'

import {  faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faMapPin  } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class AppFooter extends Component{
  render() {
    return(
        <div className="fluid">
            <div className="d-flex red" >.</div>
        <footer>
          
          <Container fluid  className="py-5 red">
           <Row>
           <div className=" col-lg-1 d-none d-md-block d-lg-block d-xl-block"> </div>
              <div className="col-md-6 col-lg-3 col-sm-12 col-xs-12 ">
                <Chile/>
              </div>
              
              <div className="col-md-6 col-lg-4 col-sm-12 col-xs-12">
                 <Peru/>
              </div>
          
          
              <div className="col-md-6 col-lg-2 col-sm-6 col-xs-6">
                <h5>Servicios</h5>
                <ul className="list-unstyled text-small">
                <li className="text-muted"> <FontAwesomeIcon icon={faChevronRight} size="sm"/>
                  <Link to="/entibaciones" className="text-secondary ">Entibaciones </Link>
                </li>
                <li className="text-muted"> <FontAwesomeIcon icon={faChevronRight} size="sm"/>
                  <Link to="/moldajes" className="text-secondary ">Moldajes </Link>
                </li>
                <li className="text-muted"> <FontAwesomeIcon icon={faChevronRight} size="sm"/>
                  <Link to="/geotecnia" className="text-secondary ">Geotecnia</Link>
                </li>
                <li className="text-muted"><FontAwesomeIcon icon={faChevronRight} size="sm"/>
                  <Link to="/asesorias" className="text-secondary ">Asesorías </Link>
                </li>
                <li className="text-muted"> <FontAwesomeIcon icon={faChevronRight} size="sm"/>
                  <Link to="/fichas" className="text-secondary ">Fichas y Catálogos </Link>
                  
                </li>
                </ul>
              </div>
              
              
              
          <div className="col-md-6 col-lg-2 col-sm-6 col-xs-6">
            <Link to="/about" className="red"><h5>Mecanotubo</h5> </Link>
            <ul className="list-unstyled text-small">
              
              <li className="text-muted" ><FontAwesomeIcon icon={faChevronRight} size="sm"/>
                <Link to="/nuestrasobras" className="text-secondary ">Proyectos </Link>
              </li>
              
              <li className="text-muted" ><FontAwesomeIcon icon={faChevronRight} size="sm"/>
              <Link to="/gallery" className="text-secondary ">Galería </Link>
              
              </li>
              
              {/* <li className="text-muted" ><FontAwesomeIcon icon={faChevronRight} size="sm"/>
              <Link to="/clientes" className="text-secondary ">Clientes </Link>
              </li> */}
              
            
             
              <li className="text-muted"> <FontAwesomeIcon icon={faChevronRight} size="sm"/>
                <Link to="/team" className="text-secondary ">Equipo </Link>
              </li>
              
              <li className="text-muted"> <FontAwesomeIcon icon={faChevronRight} size="sm"/>
                <Link to="/office" className="text-secondary ">Oficina </Link>
              </li>
            </ul>
          </div>
          </Row>
        </Container>
      </footer>
      
      
      
      <div className="d-flex">
      <Container>
        <Row className=" justify-content-md-center">
        <div className="col-2"> </div>
        <div className="col-md-auto">
          <ul className="social-network social-circle ">
                <li>
                <a
                  href="https://www.linkedin.com/company/mecanotubo-chile/"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="LinkedIn"
                >
                    <FontAwesomeIcon icon={faLinkedin} />
                </a></li>
                <li>
               
                  
                <a
                  href="https://www.youtube.com/channel/UCd24utpIjv80pPjP_dlo1kA"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="YouTube"
                >
                  <FontAwesomeIcon icon={faYoutube} /></a>
                </li>
                <li>
                  
                <a
                  href="https://goo.gl/maps/7vq4YshEogUYo2Xy9"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="Map"
                > 
                    <FontAwesomeIcon icon={faMapPin} /></a>
                  </li>
            </ul>
        </div>
        <div className="col-2"> </div>
      </Row>
  </Container>
  </div>

      <div className="d-flex red text-light" >
      <div className="container">
        <div className="row">
            <div className="col-auto ">
                 Mecanotubo © 2023
            </div>
            </div>  
            </div>
      </div> 
      
      </div>
    )
  }
}

export default AppFooter
