import React, {Component} from 'react'
import Thanks from './Thanks'

import Form from 'react-bootstrap/Form'


import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'


class ContactForm extends Component{
  
  constructor(){
    super()
    this.state={
      submitted: false
    };
    this.handleClick = this.handleClick.bind(this)
  }
  
  
  handleClick() {
    this.setState({
      submitted: true
    });
  }
 
  render(){

  return(
    <Container className='mb-5'>
      <h3 className="red text-center">Contáctenos</h3>   

  
       <iframe name="hidden_iframe"  hidden onLoad ={this.handleClick} title='render_page'></iframe> 
  
  {
    !this.state.submitted ? 
  
    <Form
    action='https://docs.google.com/forms/u/1/d/e/1FAIpQLSfCTp0dPFl-gZ9zquKpaYeeF5VVPBtrweAx1w3BUaSci2s-Mw/formResponse'
    target="hidden_iframe" 
    method="POST" 
    id="mG61Hd"
  >
    <Form.Row>
    <Col sm={12} lg={6} md={6}>
      <Form.Group controlId="name">
        <Form.Label>Nombre y Apellidos </Form.Label>
        <Form.Control placeholder="" name="entry.1327959739" required/>
      </Form.Group>
    </Col>

    <Col sm={12} lg={6} md={6}>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" placeholder="Enter email" 
        name="entry.224611608" required/>
      </Form.Group>
    </Col>
      
    <Col sm={12} lg={6} md={6}>
        <Form.Group controlId="telefono">
        <Form.Label>Teléfono de Contacto </Form.Label>
        <Form.Control placeholder=""
        name="entry.526506635" required />
      </Form.Group>
      </Col>
      
    <Col sm={12} lg={6} md={6}>

      <Form.Group controlId="name">
        <Form.Label>Nombre de su Empresa </Form.Label>
        <Form.Control placeholder=""
        name="entry.1560050235" required />
      </Form.Group>
      </Col>
    </Form.Row>
    

  <Form.Group controlId="comment">
    <Form.Label>Consultas y Comentarios</Form.Label>
    <Form.Control as="textarea" rows="3" placeholder=" Indíquenos Nombre de su Empresa, Producto o servicio que busca..."
    name="entry.952027344" required/>
  </Form.Group>

 
<Row className='justify-content-center pb-2'>
  <Button variant="secondary" type="submit" className='mb-1'  >
    Enviar
  </Button>
</Row>
  
</Form>

: 
    <Thanks/>
}  

 </Container>
 
  )  }
}

export default ContactForm
