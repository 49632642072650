import React, {Component} from 'react'
import Gallery from 'react-grid-gallery';
import {images, services} from '../../data/images'


import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';



class PhotoGallery extends Component  {
  constructor(){
    super()
    this.state={
      tag:''
    };
    this.handleClick = this.handleClick.bind(this)
  }
  
  handleClick(event) {
    this.setState({
      tag: event.target.value, 
    });
  }
 

  render(){

    const tagQry = this.state.tag
    function filterByTag(item){
      if (item.category === tagQry) {
        return true
      } else if(tagQry === ''){
        return true 
      } 
    }

    function shuffle(arr) {
      let temp, index;
      for(let i = 0; i<arr.length; i++){
        index = Math.floor(Math.random()*i)
        temp = arr[i]
        arr[i] = arr[index]
        arr[index] = temp
      } 
  
      return arr;
  }
    
  const customTagStyle = {
    wordWrap: "break-word",
    display: "inline-block",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: "auto",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: "1",
    padding: ".2em .6em .3em",
    borderRadius: ".25em",
    color: "white",
    verticalAlign: "baseline",
    margin: "2px"
};


    const IMAGES = shuffle(images.filter(filterByTag));
   
    
    const servicesBtn = services.map(service => 
      (
        <ToggleButton 
        variant="light" 
        size="sm" 
        className='buttonsGallery' 
        value={service.title} 
        onClick={this.handleClick}
        as="input" type="button"
        key={service.title}
      > 
        {service.title} 
      </ToggleButton>
      )
      
      )
    
    
    return(
     <Container fluid >
        <Row fluid >
         <Col className="col-12 text-center">
            <h1> GALERIA </h1>
            <hr/>
           <Row  >
             <Col className='align-self-end'   > 
             
        
              
              <ButtonToolbar  >
              <ToggleButtonGroup type="radio" name="options" defaultValue={''}  >
              {servicesBtn}
              
                <ToggleButton 
                  value={''} 
                  variant="light" 
                  size="sm" 
                  className='buttonsGallery' 
                  onClick={this.handleClick}
                  as="input" type="button"
                 
                > 
                  Mostrar Todas 
                </ToggleButton>
              
              
              </ToggleButtonGroup>
            </ButtonToolbar>
      
             
            </Col>
            
        
           </Row>
            
          </Col>
        </Row>
        
        
        
        
        <div className='gallery  text-center text-white'>
          <Gallery 
            images={IMAGES} 
            backdropClosesModal={true} 
            rowHeight={300}
            tagStyle={customTagStyle}
            />
        </div>

      </Container>
  )}
}


export default PhotoGallery
