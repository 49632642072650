import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {box} from  '../../data/serviceDetailEntibacion'
import {entibaciones} from  '../../data/services'

const Box = function(){
  return(
    <div>
         <Links data = {entibaciones}/>
         <General information={box} pais={'Chile y Perú.'}/>
    </div>
 
    
  )
  
}

export default Box
