import React from 'react'
import  Links from '../Links'
import GeneralGeo2 from './GeneralServiceGeo2'



import {geotecnia} from  '../../data/services'
import {fresado} from '../../data/serviceDetailGeotecnia'


const Fresado = function(){
  return(
    
    <div>
      <Links data = {geotecnia}/>
      <GeneralGeo2 information={fresado} pais={'Chile.'}/>
    </div>    
  )
  
}

export default Fresado
