export const fichas = [
  {
    name: 'Catálogos',
    detail:'Información técnica de nuestros equipos',
    list:[
      {name:'Mecanotubo' , link: './assets/files/catalogos/CAT-ENTIBACIONES-1.pdf' },
      {name:'	Accesorios' , link: './assets/files/catalogos/CAT-ACCESORIOS.pdf' },
      {name:'Cimbra' , link: './assets/files/catalogos/CAT-CIMBRA.pdf' },
      {name:'Entibaciones Piezas' , link: './assets/files/catalogos/CAT-ENTIBACIONES.pdf' },
      {name:'Especiales' , link: './assets/files/catalogos/CAT-ESPECIALES.pdf' },
      {name:'Framax' , link: './assets/files/catalogos/CAT-FRAMAX.pdf' },
      {name:'Frami' , link: './assets/files/catalogos/CAT-FRAMI.pdf' },
      {name:'Mecanoplay' , link: './assets/files/catalogos/CAT-MECANOPLAY.pdf' },
      {name:'Partes y Piezas Entibaciones' , link: './assets/files/catalogos/CAT-PARTES-Y-PIEZAS-ENTIBACIONES.pdf' },
      {name:'Soportacion' , link: './assets/files/catalogos/CAT-SOPORTACION.pdf' },
      {name:'Tekko' , link: './assets/files/catalogos/CAT-TEKKO.pdf' }
      
    ]
}, 
{
  name: 'Manuales',
  detail:'Información técnica de nuestros equipos',
  list:[
    {name:'Armado Cimbra' , link: './assets/files/manuales/PROCEDIMIENTO-ARMADO-CIMBRA.pdf' },
    {name:'Armado Guia Doble y Doble Paralelo' , link: './assets/files/manuales/PROCEDIMIENTO-ARMADO-GUIA-DOBLE-y-DOBLE-PARALELO.pdf' },
    {name:'Procedimiento de Armado KM-C-100 Box' , link: './assets/files/manuales/PROCEDIMIENTO-ARMADO-KM-C-100-BOX.pdf' },
    {name:'Procedimiento Montaje Entibaciones Minibox 2020' , link: './assets/files/manuales/PROCEDIMIENTO-MONTAJE-ENTIBACIONES-MINIBOX-2020.pdf' },		
  ]
}
]
