import React from 'react';
import {AppNav, AppFooter} from './components'
import Routes from './routes'

function App() {
  return (
   
    <div className="root">
      <AppNav/>
      <Routes />
      <AppFooter/>
    </div>
  );
}

export default App;
