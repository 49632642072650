import React from 'react'
import {Link} from 'react-router-dom'


import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare} from '@fortawesome/free-solid-svg-icons'

function Gallery(props){
  
  const projects = props.projects
  return(
  
      <Container fluid >
        <Row className="px-2 py-3 m-2">
        {projects.map(project => 
        
        <Col xlg={4} lg={4} xs={12} md={6} sm={12} className="pb-3 card-group" key={project.name}>
     
          <Card className="border-light" > 
            <Card.Img variant="top" src=   {project.img}  />
            <Card.Body>
              
              <Card.Title className="redDark">  {project.name} </Card.Title>
              <Card.Text>
              {project.description}  <br/>
              <span className="center">
                {project.link ?
                    <Link to={project.link} className="p-1 w-50 red">Ver Más   
                    <FontAwesomeIcon icon={faShare} size="2x" className="pl-2" />
                  </Link>
                  : null
                }
              </span>
              
              </Card.Text>
             
            </Card.Body>
          </Card>
          
        </Col>
        
         )}
        </Row>
      </Container>
  )
}


export default Gallery
