export const services = [{
  title: "Entibaciones",
},
{ title: "Geotecnia",
},
{ title:"Moldaje",
}

]

export const images =
[
  
  {
    src: "./assets/gallery/entibacion01.jpg",
    thumbnail: "./assets/gallery/entibacion01.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones",
    tags: [{value: "Entibaciones", title: "Entibaciones"}],
    category:'Entibaciones'
},

{
  src: "./assets/gallery/entibacion02.jpg",
  thumbnail: "./assets/gallery/entibacion02.jpg",
  thumbnailWidth: 320,
  thumbnailHeight: 174,
  caption: "Entibaciones",
  tags: [{value: "Entibaciones", title: "Entibaciones"}],
  category:'Entibaciones'
},

{
  src: "./assets/gallery/entibacion03.jpg",
  thumbnail: "./assets/gallery/entibacion03.jpg",
  thumbnailWidth: 320,
  thumbnailHeight: 174,
  caption: "Entibaciones",
  tags: [{value: "Entibaciones", title: "Entibaciones"}],
  category:'Entibaciones'
},

{
  src: "./assets/gallery/entibacion04.jpg",
  thumbnail: "./assets/gallery/entibacion04.jpg",
  thumbnailWidth: 320,
  thumbnailHeight: 174,
  caption: "Entibaciones",
  tags: [{value: "Entibaciones", title: "Entibaciones"}],
  category:'Entibaciones'
},
 
{
  src: "./assets/gallery/entibacion05.jpg",
  thumbnail: "./assets/gallery/entibacion05.jpg",
  thumbnailWidth: 320,
  thumbnailHeight: 174,
  caption: "Entibaciones",
  tags: [{value: "Entibaciones", title: "Entibaciones"}],
  category:'Entibaciones'
},



      {
            src: "./assets/gallery/entibacion06.jpg",
            thumbnail: "./assets/gallery/entibacion06.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 174,
            caption: "Entibaciones - Box KM100 - en obra.",
            tags: [{value: "Box KMC-100", title: "Box KMC-100"}],
            category:'Entibaciones'
    },
    {
      src: "./assets/gallery/entibacion07.jpg",
      thumbnail: "./assets/gallery/entibacion07.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Box KM100",
      tags: [{value: "Box KMC-100", title: "Box KMC-100"}],
      category:'Entibaciones'
    },
    
    {
      src: "./assets/gallery/entibacion08.jpg",
      thumbnail: "./assets/gallery/entibacion08.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones",
      tags: [{value: "Entibaciones", title: "Entibaciones"}],
      category:'Entibaciones'
    },
    {
      src: "./assets/gallery/entibacion09.jpg",
      thumbnail: "./assets/gallery/entibacion09.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones",
      tags: [{value: "Entibaciones", title: "Entibaciones"}],
      category:'Entibaciones'
    },
    
    
    
    {
      src: "./assets/gallery/entibacion_box01.jpg",
      thumbnail: "./assets/gallery/entibacion_box01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Box",
      tags: [{value: "Box", title: "Box"}],
      category:'Entibaciones'
},
    
  {
    src: "./assets/gallery/entibacion_box02.jpg",
    thumbnail: "./assets/gallery/entibacion_box02.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Box",
    tags: [{value: "Box", title: "Box"}],
    category:'Entibaciones'
  },
  
  {
    src: "./assets/gallery/entibacion_box03.jpg",
    thumbnail: "./assets/gallery/entibacion_box03.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Box",
    tags: [{value: "Box", title: "Box"}],
    category:'Entibaciones'
  },

  
  {
    src: "./assets/gallery/entibacion_box04.jpg",
    thumbnail: "./assets/gallery/entibacion_box04.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Box",
    tags: [{value: "Box", title: "Box"}],
    category:'Entibaciones'
  },

  
  {
    src: "./assets/gallery/entibacion_box05.jpg",
    thumbnail: "./assets/gallery/entibacion_box05.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Box",
    tags: [{value: "Box", title: "Box"}],
    category:'Entibaciones'
  },

  {
    src: "./assets/gallery/entibacion_box06.jpg",
    thumbnail: "./assets/gallery/entibacion_box06.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Box",
    tags: [{value: "Box", title: "Box"}],
    category:'Entibaciones'
  },

  
  {
    src: "./assets/gallery/entibacion_box07.jpg",
    thumbnail: "./assets/gallery/entibacion_box07.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Box",
    tags: [{value: "Box", title: "Box"}],
    category:'Entibaciones'
  },
  
  
  {
    src: "./assets/gallery/entibacion_box08.jpg",
    thumbnail: "./assets/gallery/entibacion_box08.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Box",
    tags: [{value: "Box", title: "Box"}],
    category:'Entibaciones'
  },



  {
    src: "./assets/gallery/guiadoble01.jpg",
    thumbnail: "./assets/gallery/guiadoble01.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },
  {
    src: "./assets/gallery/guiadoble02.jpg",
    thumbnail: "./assets/gallery/guiadoble02.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },
  {
    src: "./assets/gallery/guiadoble03.jpg",
    thumbnail: "./assets/gallery/guiadoble03.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },

  {
    src: "./assets/gallery/guiadoble04.jpg",
    thumbnail: "./assets/gallery/guiadoble04.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },

  {
    src: "./assets/gallery/guiadoble05.jpg",
    thumbnail: "./assets/gallery/guiadoble05.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },
  {
    src: "./assets/gallery/guiadoble06.jpg",
    thumbnail: "./assets/gallery/guiadoble06.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },

  {
    src: "./assets/gallery/guiadoble07.jpg",
    thumbnail: "./assets/gallery/guiadoble07.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },

  {
    src: "./assets/gallery/guiadoble08.jpg",
    thumbnail: "./assets/gallery/guiadoble08.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },
  {
    src: "./assets/gallery/guiadoble09.jpg",
    thumbnail: "./assets/gallery/guiadoble09.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },
  {
    src: "./assets/gallery/guiadoble10.jpg",
    thumbnail: "./assets/gallery/guiadoble10.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },
  
  {
    src: "./assets/gallery/guiadoble11.jpg",
    thumbnail: "./assets/gallery/guiadoble11.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: "Entibaciones - Guía Doble",
    tags: [{value: "Guía Doble", title: "Guía Doble"}],
    category:'Entibaciones'
  },



    
  
    {
      src: "./assets/gallery/KMGD-01.jpg",
      thumbnail: "./assets/gallery/KMGD-01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble KMGD",
      tags: [{value: "Guía Doble KMGD", title: "Guía Doble KMGD"}],
      category:'Entibaciones'
    },

 
    
    {
      src: "./assets/gallery/KMGD-03.jpg",
      thumbnail: "./assets/gallery/KMGD-03.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble KMGD",
      tags: [{value: "Guía Doble KMGD", title: "Guía Doble KMGD"}],
      category:'Entibaciones'
    },
    
    
    {
      src: "./assets/gallery/KMGDDP01.jpg",
      thumbnail: "./assets/gallery/KMGDDP01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Paralelo KMGDDP",
      tags: [{value: "Guía Doble Paralelo KMGDDP", title: "Guía Doble Paralelo KMGDDP"}],
      category:'Entibaciones'
    },
    
    {
      src: "./assets/gallery/KMGDDP02.jpg",
      thumbnail: "./assets/gallery/KMGDDP02.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Paralelo KMGDDP",
      tags: [{value: "Guía Doble Paralelo KMGDDP", title: "Guía Doble Paralelo KMGDDP"}],
      category:'Entibaciones'
    },
    
       
    {
      src: "./assets/gallery/KMGDDP03.jpg",
      thumbnail: "./assets/gallery/KMGDDP03.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Paralelo KMGDDP",
      tags: [{value: "Guía Doble Paralelo KMGDDP", title: "Guía Doble Paralelo KMGDDP"}],
      category:'Entibaciones'
    },
       
    {
      src: "./assets/gallery/KMGDDP04.jpg",
      thumbnail: "./assets/gallery/KMGDDP04.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Paralelo KMGDDP",
      tags: [{value: "Guía Doble Paralelo KMGDDP", title: "Guía Doble Paralelo KMGDDP"}],
      category:'Entibaciones'
    },
       
    {
      src: "./assets/gallery/KMGDDP05.jpg",
      thumbnail: "./assets/gallery/KMGDDP05.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Paralelo KMGDDP",
      tags: [{value: "Guía Doble Paralelo KMGDDP", title: "Guía Doble Paralelo KMGDDP"}],
      category:'Entibaciones'
    },
    
    {
      src: "./assets/gallery/KMGDDP06.jpg",
      thumbnail: "./assets/gallery/KMGDDP06.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Paralelo KMGDDP",
      tags: [{value: "Guía Doble Paralelo KMGDDP", title: "Guía Doble Paralelo KMGDDP"}],
      category:'Entibaciones'
    },
    
   
    {
      src: "./assets/gallery/DobleEsquina.jpg",
      thumbnail: "./assets/gallery/DobleEsquina.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Esquina",
      tags: [{value: "Guía Doble Esquina", title: "Guía Doble Esquina"}],
      category:'Entibaciones'
    },
    
    {
      src: "./assets/gallery/guiaCombinada01.jpg",
      thumbnail: "./assets/gallery/guiaCombinada01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones",
      tags: [{value: "Guía Combinada", title: "Guía Combinada"}],
      category:'Entibaciones'
    },
    
    
    {
      src: "./assets/gallery/DobleEsquina02.jpg",
      thumbnail: "./assets/gallery/DobleEsquina02.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Entibaciones - Guía Doble Esquina",
      tags: [{value: "Guía Doble Esquina", title: "Guía Doble Esquina"}],
      category:'Entibaciones'
    },
    
    
    {
      src: "./assets/gallery/moldaje01.jpg",
      thumbnail: "./assets/gallery/moldaje01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    {
      src: "./assets/gallery/moldaje02.jpg",
      thumbnail: "./assets/gallery/moldaje02.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    {
      src: "./assets/gallery/moldaje03.jpg",
      thumbnail: "./assets/gallery/moldaje03.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/moldaje04.jpg",
      thumbnail: "./assets/gallery/moldaje04.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/moldaje05.jpg",
      thumbnail: "./assets/gallery/moldaje05.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/encofrado01.jpg",
      thumbnail: "./assets/gallery/encofrado01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    {
      src: "./assets/gallery/encofrado02.jpg",
      thumbnail: "./assets/gallery/encofrado02.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/encofrado03.jpg",
      thumbnail: "./assets/gallery/encofrado03.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/encofrado04.jpg",
      thumbnail: "./assets/gallery/encofrado04.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },

    {
      src: "./assets/gallery/encofrado05.jpg",
      thumbnail: "./assets/gallery/encofrado05.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    {
      src: "./assets/gallery/encofrado06.jpg",
      thumbnail: "./assets/gallery/encofrado06.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    {
      src: "./assets/gallery/encofrado07.jpg",
      thumbnail: "./assets/gallery/encofrado07.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Encofrado",
      tags: [{value: "Encofrado", title: "Encofrado"}],
      category:'Moldaje'
    },
    {
      src: "./assets/gallery/cimbra01.jpg",
      thumbnail: "./assets/gallery/cimbra01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Soportación",
      tags: [{value: "Cimbra", title: "Cimbra"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/cimbra02.jpg",
      thumbnail: "./assets/gallery/cimbra02.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Soportación",
      tags: [{value: "Cimbra", title: "Cimbra"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/cimbra03.jpg",
      thumbnail: "./assets/gallery/cimbra03.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Soportación",
      tags: [{value: "Cimbra", title: "Cimbra"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/cimbra04.jpg",
      thumbnail: "./assets/gallery/cimbra04.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Soportación",
      tags: [{value: "Cimbra", title: "Cimbra"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/cimbra05.jpg",
      thumbnail: "./assets/gallery/cimbra05.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Soportación",
      tags: [{value: "Cimbra", title: "Cimbra"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/cimbra06.jpg",
      thumbnail: "./assets/gallery/cimbra06.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Moldajes Soportación",
      tags: [{value: "Cimbra", title: "Cimbra"}],
      category:'Moldaje'
    },
    
    {
      src: "./assets/gallery/especiales01.jpg",
      thumbnail: "./assets/gallery/especiales01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Carro Metro",
      tags: [{value: "Soluciones Especiales", title: "Soluciones Especiales"}],
      category:'Moldaje'
    },
    
        
   
    
    
    
    {
      src: "./assets/gallery/geotecnica01-04b.jpg",
      thumbnail: "./assets/gallery/geotecnica01-04b.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },

    {
      src: "./assets/gallery/geotecnica01-04c.jpg",
      thumbnail: "./assets/gallery/geotecnica01-04c.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/geotecnica01-04e.jpg",
      thumbnail: "./assets/gallery/geotecnica01-04e.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    {
      src: "./assets/gallery/geotecnica01-04f.jpg",
      thumbnail: "./assets/gallery/geotecnica01-04f.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/rtg01.jpg",
      thumbnail: "./assets/gallery/rtg01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    {
      src: "./assets/gallery/rtg02.jpg",
      thumbnail: "./assets/gallery/rtg02.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    {
      src: "./assets/gallery/rtg03.jpg",
      thumbnail: "./assets/gallery/rtg03.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    
    
    {
      src: "./assets/gallery/rtg04.jpg",
      thumbnail: "./assets/gallery/rtg04.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    {
      src: "./assets/gallery/geotecnica6.jpg",
      thumbnail: "./assets/gallery/geotecnica6.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/geotecnica7.jpg",
      thumbnail: "./assets/gallery/geotecnica7.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/geotecnica8.jpg",
      thumbnail: "./assets/gallery/geotecnica8.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Hincado y Retiro de Tablestacas",
      tags: [{value: "Hincado y Retiro de Tablestacas", title: "Hincado y Retiro de Tablestacas"}],
      category:'Geotecnia'
    },
    
    
    
    
    
    
    
    {
      src: "./assets/gallery/geotecnica02-3.jpg",
      thumbnail: "./assets/gallery/geotecnica02-3.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Descabezado de Pilotes",
      tags: [{value: "Descabezado de Pilotes", title: "Descabezado de Pilotes"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/geotecnica3-01.jpg",
      thumbnail: "./assets/gallery/geotecnica3-01.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Fresado de Muros & Elementos de Hormigón",
      tags: [{value: "Fresado de Muros & Elementos de Hormigón", title: "Fresado de Muros & Elementos de Hormigón"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/geotecnica3-02.jpg",
      thumbnail: "./assets/gallery/geotecnica3-02.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Fresado de Muros & Elementos de Hormigón",
      tags: [{value: "Fresado de Muros & Elementos de Hormigón", title: "Fresado de Muros & Elementos de Hormigón"}],
      category:'Geotecnia'
    },
    {
      src: "./assets/gallery/geotecnica4.jpg",
      thumbnail: "./assets/gallery/geotecnica4.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Perforaciones en Hormigón (Verticales y Horizontales)",
      tags: [{value: "Perforaciones en Hormigón (Verticales y Horizontales)", title: "Perforaciones en Hormigón (Verticales y Horizontales)"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/geotecnica5.jpg",
      thumbnail: "./assets/gallery/geotecnica5.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Demolición de Hormigón - Picado, Fresado & Cizalla",
      tags: [{value: "Demolición de Hormigón - Picado, Fresado & Cizalla", title: "Demolición de Hormigón - Picado, Fresado & Cizalla"}],
      category:'Geotecnia'
    },
    
    {
      src: "./assets/gallery/geotecnica5a.jpg",
      thumbnail: "./assets/gallery/geotecnica5a.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "Demolición de Hormigón - Picado, Fresado & Cizalla",
      tags: [{value: "Demolición de Hormigón - Picado, Fresado & Cizalla", title: "Demolición de Hormigón - Picado, Fresado & Cizalla"}],
      category:'Geotecnia'
    },
    
   
   
    // {
    //   src: "./assets/gallery/20210802_14-04-55.jpg",
    //   thumbnail: "./assets/gallery/20210802_14-04-55.jpg",
    //   thumbnailWidth: 320,
    //   thumbnailHeight: 320,
    //   caption: "Box KMC 2021",
    //   tags: [{value: "Box KMC 2021", title: "Box KMC 2021"}],
    //   category:'Entibaciones'
    // },
    // {
    //   src: "./assets/gallery/20210802_14-04-56.jpg",
    //   thumbnail: "./assets/gallery/20210802_14-04-56.jpg",
    //   thumbnailWidth: 320,
    //   thumbnailHeight: 320,
    //   caption: "Box KMC 2021",
    //   tags: [{value: "Box KMC 2021", title: "Box KMC 2021"}],
    //   category:'Entibaciones'
    // },
    
       
    // {
    //   src: "./assets/gallery/KMGD-02.jpg",
    //   thumbnail: "./assets/gallery/KMGD-02.jpg",
    //   thumbnailWidth: 320,
    //   thumbnailHeight: 174,
    //   caption: "Entibaciones - Guía Doble KMGD",
    //   tags: [{value: "Guía Doble KMGD", title: "Guía Doble KMGD"}],
    //   category:'Entibaciones'
    // },
    

]
