import React from 'react'
import Disponibilidad from '../Disponibilidad'


import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh} from '@fortawesome/free-solid-svg-icons'
import {  faSteamSquare } from '@fortawesome/free-brands-svg-icons'

function General(props){
  
  const information = props.information

  const table = information.services.map( service => (
    
   <div>
     <Container className='col-12 mt-5'>
     {service.name ? 
       <h4> <FontAwesomeIcon icon={faSteamSquare} size="sm" className="redLink" /> {service.name} </h4>
      : null
    }
     
  
     <Row className="mx-auto mt-5 ">
        
          <Col className="col-xlg-7 col-lg-7 col-12 mx-auto  ">
              <img
              width={'auto'}
              height={'auto'}
              className="mx-auto img-fluid"
              src={service.img} 
              alt={service.name}
              />
          </Col>
          
          <Col className="col-xlg-5 col-lg-5 col-md-12 col-12 mx-auto text-left">
            <Table responsive className='border'>
            <thead>
              {service.tableHeaders.map(ele =>
                 <tr className='border'>
                 <th> <FontAwesomeIcon icon={faTh} size="sm" className="redLink" /> {ele.header}</th>
                 <th className='text-right'>{ele.detail}</th>
         
               </tr>
                )}
            </thead>
            <tbody>
              {service.table.map(ele =>
                 <tr>
                 <td>{ele.title}</td>
                 <td className='text-right'>{ele.dim}</td>
                 
               </tr>
                )}
            </tbody>
          </Table>
          
          { service.img2 ? 
          <Col className="col-12 mx-auto  ">
              <img
              width={'auto'}
              height={200}
              className="mx-auto img-fluid"
              src={service.img2} 
              alt={service.name}
              />
          </Col> : null
        
        }
          
          
           </Col>
     </Row>
       </Container>
     
   </div> 
    
   
  ))
  
  return(
  <Jumbotron fluid  className="m-0 ">
  <Container fluid  >
    <h1 className="text-center red">{information.title}</h1>
    <h2 className="text-center">{information.subtitle}</h2>
    <h5 className="text-center">{information.description}</h5>
   

  
           {table}
         
  
 
        
    
    <br/>
    <Disponibilidad pais={props.pais}/>

  </Container>
</Jumbotron>
  )
  
}

export default General
