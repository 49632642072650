import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {frami} from  '../../data/serviceDetailMoldaje'
import {encofrado} from  '../../data/services'


const Frami = function(){
 
  
  return(
    <div>
         <Links data = {encofrado}/>
         <General information={frami} pais={'Chile.'}/>
    </div>
 
    
  )
  
}

export default Frami
