import React from 'react';
import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

function Flags() {
  return (
    <Row className=" d-flex flex-row  align-self-center m-1 ">
      <Col
        xlg={12}
        lg={2}
        xs={12}
        md={8}
        sm={12}
        className="redDark ml-auto p-2   text-right"
      >
        Mecanotubo en:
      </Col>

      <Col
        xlg={12}
        lg={3}
        xs={12}
        md={8}
        sm={12}
        className=" text-secondary   p-2   text-right"
      >
        <Link className=" text-secondary " to="/home" rel="noopener noreferrer">
          <Image className="flag ml-1" src="/assets/img/flagChile.png" />
          <span className="pr-1 ">Chile |</span>
        </Link>
        <a
          className="text-secondary"
          href="http://www.mecanotubo.pe/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image className="flag ml-1" src="/assets/img/flagPeru.jpg" />{' '}
          <span className="pr-1  ">Perú |</span>
        </a>
      
      </Col>
    </Row>
  );
}

export default Flags;
