import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {framax} from  '../../data/serviceDetailMoldaje'
import {encofrado} from  '../../data/services'


const Framax = function(){
 
  
  return(
    <div>
         <Links data = {encofrado}/>
         <General information={framax} pais={'Chile.'}/>
    </div>
 
    
  )
  
}

export default Framax
