import React from 'react'
import Disponibilidad from '../Disponibilidad'


import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'

import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSteamSquare } from '@fortawesome/free-brands-svg-icons'

function GeneralGeo2(props){
  
  const information = props.information

  const table = information.services.map( service => (
    
   <div>
     <Container className='col-12 mt-5'>
     {service.name ? 
       <h4> <FontAwesomeIcon icon={faSteamSquare} size="sm" className="redLink" /> {service.name} </h4>
      : null
    }
    
 {service.imgs ?
     <Row className="mx-auto">         
     
     <Col className=" col-6 ">
        <img
        width={'auto'}
        height={'auto'}
        className="img-fluid"
        src={service.img} 
        alt={service.name}
        />
    
    </Col>
    
    <Col className=" col-6 ">
       <Row>
          
            {service.imgs.map(img => (
              <Col className="col-6 p-1 ">
              <img
              width={'auto'}
              height={'auto'}
              className="img-fluid"
              src={img} 
              alt={img}
              />
              </Col> 
             )) }
          
       </Row>
    </Col>
    
    
     </Row> : 
     
     
     <Row className="mx-auto">         
     
     <Col className=" col-12">
        <img
        width={'auto'}
        height={'auto'}
        className="img-fluid mx-auto d-block"
        src={service.img} 
        alt={service.name}
        />
    
    </Col>
    
    </Row>
          }
       </Container>
     
   </div> 
    
   
  ))
  
  return(
  <Jumbotron fluid  className="m-0 ">
  <Container fluid  >
    <h1 className="text-center red">{information.title}</h1>
    <h2 className="text-center">{information.subtitle}</h2>
    <h5 className="text-center">{information.description}</h5>
   

  
           {table}
         
  
 
        
    
    <br/>
    <Disponibilidad pais={props.pais}/>

  </Container>
</Jumbotron>
  )
  
}

export default GeneralGeo2
