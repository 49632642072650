import React from 'react'


import General from '../General'



function Asesoria(){
  
  let title = 'ASESORIAS'
  let text = 'Mecanotubo cuenta con el respaldo técnico de sus profesionales, el cual nos permite asesorar de forma eficiente y oportuna. Destacando siempre la importancia de nuestros clientes, atendiendo sus necesidades en el menor tiempo posible.'
  
   
  return(

 
    <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgAses'
            detail = {''} list={''}  />
   
            
          // add Form here !!! 
  )
}

export default Asesoria
