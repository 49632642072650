import React from 'react'
import {Link} from 'react-router-dom'

import {moldaje} from '../../data/services'


import General from '../General'
import Disponibilidad from '../Disponibilidad'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCity} from '@fortawesome/free-solid-svg-icons'

function Moldajes(){
  
  let title = 'MOLDAJES'
  let text = 'Es una estructura temporal que soporta la carga propia del hormigón fresco y de las sobrecargas de personas, equipos y otros elementos que se especifiquen.'
  
  let detail = {
    subtitle: 'Sistemas para Soporte de Losa, Encofrado de Muros y Soluciones Especiales de Hormigonado',
    parragraph: "Un sistema de moldaje es un conjunto de elementos dispuestos de forma tal que cumple con la función de moldear el hormigón fresco a la forma y tamaño especificado, controlando su posición y alineamiento dentro de las tolerancias exigidas. Es una estructura temporal que soporta la carga propia del hormigón fresco y de las sobre cargas de personas, equipos y otros elementos que se especifiquen. Contamos con una amplia gama de productos tanto para la venta como para el arriendo, entre los que se encuentran: sistemas para soporte de losa, encofrado de muros y soluciones especiales de hormigonado."
  }

  let menu = moldaje.list
 
  
  const list = menu.map(ele =>{

    return (
      <div className="p-1 px-1 w-50 " key={ele.name}> 
       <FontAwesomeIcon icon={faCity} size="sm" className="redLink"/>
       <Link to={ele.link} className="text-dark font-weight-bold" > {ele.name} </Link>
       <ul className="list">
           {ele.detail.map(detail => {
           return(
             <li key={detail.name}>
               <Link to={detail.link} className="text-dark font-weight-bold" > {detail.name} </Link>
             </li>
             
           )
         })}
       </ul>
       
     </div>
    )
  })
  

  

  return(
    <div>
        <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgMoldaje' detail={detail} list={list} />
        <Disponibilidad pais={'Chile.'}/>
    </div>
  )
}

export default Moldajes
