import React from 'react'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

function HomeBtns(props) {
  
  return (
    
   <Container>
    <Row className={props.position}>
    <div className=" col-md-auto">
   
      <Link to='/entibaciones' className="btn-secondary">
         <Button  className="btn-secondary btn-lg">
        ENTIBACIONES  
        </Button>
      </Link>
      
    
    </div>
    <div className="col-md-auto">
      
    <Link to='/moldajes' className="btn-secondary">
         <Button  className="btn-secondary btn-lg">
        MOLDAJES  
        </Button>
      </Link>
    </div>
    <div className=" col-md-auto">
      
    <Link to='/geotecnia' className="btn-secondary">
         <Button  className="btn-secondary btn-lg">
        GEOTECNIA  
        </Button>
      </Link>

    </div>
    </Row>
  </Container>

    )
}

export default HomeBtns
