import React from 'react'
import {countries} from '../../data/countries'
import ContactForm from '../Form/ContactForm'
import General from '../General'
import Map from '../Map'
import {Chile, Peru} from '../ContactInfo'


import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Office(){
  
  let title = 'OFICINAS'
  let text = 'Mecanotubo se encuentra en Chile y Perú. Nuestras ubicaciones estratégicamente escogidas para llegar a todo el país y prestar el mejor servicio.'
  let subtitulo = 'Nuestras Oficinas en Chile y Perú'

   
  return(
    <div>
      <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgOffice'
                detail = {''} list={''}  subtitulo={subtitulo} />
                
                
                
      <Row className="m-3">
        {countries.map( country => 
        <Col xlg={6} lg={6} xs={12} md={12} sm={12}>     
        <Card className='border'>
          <Card.Title className="redDark text-center"> Oficina {country.name} </Card.Title>
            <div className="text-center text-secondary" >   
            <Map pais={country.name} map = {country.map}/>   
            </div> 
            <Card.Body className=" text-center">
              {country.name === 'Chile' ? <Chile/> : <Peru/>}
            </Card.Body>
        </Card>
        </Col>
        
      )}
 
      </Row>
      
      <Row>
        
        <ContactForm/>
        
        
      </Row>
    
    </div>
 
   
  )
}

export default Office
