 export const projects = [
    // {
    // id	:	1	,
    // name	:"	Mall Plaza Sur	",
    // description	:"	Obra realizada en 2007. Mecanotubo Chile prestó asesoría, realizó estudios y modulaciones de fundaciones corridas, pilares, muros a 6.0 mts. de altura, además la soportacion de losas, vigas y capiteles en las distintas etapas de la construcción.	",
    // img	:"	./assets/img/projects/p-plaza_sur_thumb.png	"},
    
    
    {
      id	:	0	,
      name	:"Estadio San Carlos de Apoquindo",
      description	:"Obra en construcción año 2023. Mecanotubo Chile suministra sistema cimbra para la construcción.",
      img	:"./assets/img/projects/p-estadio-san-carlos.png"},
      
    
      {
    id	:	1	,
    name	:"Edificio Martinez de Rozas",
    description	:"Obra realizada en 2022 Mecanotubo Chile ejecuto modulaciones de sistema de moldajes. Sociedad Constructora Rupanco.",
    img	:"./assets/img/projects/p-martinezrozas.png"},
    
    // {
    // id	:	2	,
    // name	:"	Estanques Aconcagua Foods 	",
    // description	:"	Planta de tratamiento de aguas perteneciente a la Empresa Aconcagua Foods, ubicada en José Alberto Bravo 0278, Buin, Región Metropolitana 	",
    // img	:"	./assets/img/projects/p-estanques_aconcagua.png	"},
    
    {
      id	:	2	,
      name	:"Casas Valle las Araucarias.",
      description	:"Obra realizada en 2021 Mecanotubo chile realizo modulaciones de sistema de moldajes y soportación. Constructora Ignacio Hurtado.",
      img	:"./assets/img/projects/p-casas-valle.png"},
      
    // {
    // id	:	3	,
    // name	:"	Museo de Carabineros	",
    // description	:"	Ubicada en Vasconia esquina Antonio Varas. Fue realizada el año 2008 con la asesoría de Mecanotubo Chile S.A. en las soluciones de soportacion de losas, vigas, moldaje de muros a 2 caras y muros contra terreno	",
    // img	:"	./assets/img/projects/p-museo_carabineros_thumb.png	"},
    
   {
    id	:	3	,
    name	:"Condominio alta Vista Chicureo.",
    description	:"Obra realizada en 2021 mecanotubo chile ejecuto modulaciones de sistema de moldajes y soportación. Constructora Francisco Lorca",
    img	:"./assets/img/projects/p-vista-chicureo.png"},
    // {
    // id	:	4	,
    // name	:"	Edificio Marcoleta 	",
    // description	:"	Obra de edificación realizada el año 2010 y ubicada en Marcoleta 462, Santiago. Consta de 2 edificios: Marcoleta 1, de 4 subterráneos y 21 pisos habitacionales; y Marcoleta 2, de 4 subterráneos y 4 pisos de oficinas.	",
    // img	:"	./assets/img/projects/p-edificioMarcoleta.png	"},
    // {
    // id	:	5	,
    // name	:"	Ferreteria O'Higgins 	",
    // description	:"	Las Oficinas del Centro de Distribución de Ferretería O’Higgins, ubicada en el parque industrial ENEA en Santiago, fueron construidas el año 2010.	",
    // img	:"	./assets/img/projects/p-ferreteria_ohiggins_thumb.png	"},
    
        {
    id	:	5	,
    name	:"Taiquen II",
    description	:"Obra Realizada en 2021-2022 mecanotubo chile ejecuto modulaciones de sistema de moldajes y soportación. Constructora Ignacio Hurtado.",
    img	:"./assets/img/projects/p-taiquen.png"},
    
    // {
    // id	:	6	,
    // name	:"	Centro de Molienda Puzolana 	",
    // description	:"	Obra Civil realizada el año 2011, ubicada en Planta Chena, San Bernardo, Santiago. Las obras civiles comprendieron el edificio de molienda, galpón de ensacado y urbanización. 	",
    // img	:"	./assets/img/projects/p-molienda.png	"},
    
       {
    id	:	6	,
    name	:"Estadio San Antonio",
    description	:"Obra Realizada en 2019 mecanotubo chile ejecuto modulaciones de sistema de Cimbra. Constructora Valko",
    img	:"	./assets/img/projects/p-estadio-san-antonio.png	"},
    
    {
      id	:	13	,
      name	:"	Estadio La Calera	",
      description	:"	Obra realizada en 2018. Mecanotubo Chile presto asesoria, realizo estudio y modulaciones de sistema Cimbra. Constructora Valko.",
      img	:"	./assets/img/projects/p-laCalera.png"},
      
      {
        id	:	16	,
        name	:"	Borde Costero Constitucion	",
        description	:"Obra Realizada en 2017 Maquina RTG. Cliente: Arrigoni Ingeniería Construcción S.A",
        img	:"./assets/img/projects/p-bordecostero.png"},
      {
        id	:	14	,
        name	:"	Teatro La Pintana	",
        description	:"	Construido en 2016-2017, se ubica en la comuna de La Pintana, Santiago. Cliente: Rodolfo Espina Santander 	",
        img	:"	./assets/img/projects/p-teatroLaPintana.jpg	"},
    {
      id	:	9	,
      name	:"	Campo Deportivo Juan Gómez Milla	",
      description	:"	Obra fue realizada el año 2016, ubicada en Ignacio Carrera Pinto 1045 Nuñoa, superficie 8500 m2. Constructora: PitagoraS.A. 	",
      img	:"	./assets/img/projects/p-juang.jpg	"},
    
    {
    id	:	7	,
    name	:"	Edificio Consistorial	",
    description	:"	Construido en 2015, se ubica en la comuna de Lo Barnechea, Santiago. Con una superficie de 17.520m2 construidos. Cliente: Proyekta	",
    img	:"	./assets/img/projects/p-consistorial.jpg	"},
    {
    id	:	8	,
    name	:"	Gimnasio Saint George	",
    description	:"	Construido en 2015, se ubica en la comuna de Vitacura, Santiago. Con una superficie de 9.110m2 construidos. Cliente: Proyekta 	",
    img	:"	./assets/img/projects/p-gimnasio.jpg	"},
   
    // {
    // id	:	10	,
    // name	:"	Puerto San Antonio	",
    // description	:"	Construido en 2016, Maquina RTG. Cliente: Consorcio Nave Brotec S.A.	",
    // img	:"	./assets/img/projects/p-puertosa.jpg	"},
    // {
    // id	:	11	,
    // name	:"	Edificio Luis Carrera	",
    // description	:"	Construido en 2016-2017, se ubica en la comuna de Vitacura, Santiago. Cliente: Proyekta 	",
    // img	:"	./assets/img/projects/p-edificioLuis.jpg	"},
    // {
    // id	:	12	,
    // name	:"	Motorshop	",
    // description	:"	Obra actualmente en construcción desde 2017, ubicada en la comuna de Renca, Santiago. Cuenta con 8.116m2 construidos. Cliente: Proyekta.	",
    // img	:"	./assets/img/projects/p-motorshop.jpg	"},
   
    
    
    


]
