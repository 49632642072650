import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import {moldaje, entibaciones, geotecnia, about, fichas} from  '../data/services'
import Flags from './Flags'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCamera , faComments } from '@fortawesome/free-solid-svg-icons'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import SplitButton from 'react-bootstrap/SplitButton'
import OverlayTrigger  from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
 

export default function AppNav(){
  const LinksEntibacion =  entibaciones.list.map(el => {
    return (
     <Dropdown.Item>
       <Link className="dropdown-item" to={el.link} key={el.name}> 
          {el.name}  
       </Link>
     </Dropdown.Item>
    )}
  )    
  
  const LinksGeotecnia =  geotecnia.list.map(el => {
    return (
      <Dropdown.Item>        
        <Link className="dropdown-item" to={el.link} key={el.name}> {el.name} </Link>
      </Dropdown.Item>
    )}
  ) 
  
  const LinksModalaje=  moldaje.list.map(moldaje => {
    return (
      
      <Link to={moldaje.link}  key={moldaje.id} className='nonDecoration'> 
          <DropdownButton 
            id="dropdown-basic-button" 
            title={moldaje.name} size="md"  
            variant='light'
          >

              {moldaje.detail.map(el => {
                return (
                  <Dropdown.Item >
                    <Link className="dropdown-item" to={el.link} key={el.name} > {el.name} </Link>
                  </Dropdown.Item>  
                )
                })
              }
          </DropdownButton>
            {/* </SplitButton>
        </ButtonToolbar> */}
     
    </Link>
    )}
  )    
  
  const LinksAbout=  about.list.map(el => {
    return (
      <Dropdown.Item>        
        <Link className="dropdown-item"  to={el.link} key={el.name}> {el.name} </Link>
      </Dropdown.Item>
    )}
  ) 
  
  
  const LinksFichas =  fichas.list.map(el => {
    return (
      <Dropdown.Item>        
        <Link className="dropdown-item" to={el.link} key={el.name}> {el.name} </Link>
      </Dropdown.Item>
    )}
  ) 
  
  const ListNav = [
    {link:entibaciones.link, title: entibaciones.title, list:LinksEntibacion},
    {link:moldaje.link, title: moldaje.title, list:LinksModalaje},
    {link:geotecnia.link, title: geotecnia.title, list:LinksGeotecnia},
    {link:fichas.link, title:fichas.title,list:LinksFichas},
    {link:about.link, title:about.title, list:LinksAbout}
  ] 
  
  const IconNav = [
    {tooltip :"Galería de Fotos", link:"/gallery", icon:faCamera},
    {tooltip :"Contacto", link:"/contact", icon:faComments},
    {tooltip :"Inicio", link:"/", icon:faHome},

  ]
  

  return (
  <div> 
    <Navbar bg="light" expand="lg"  >
      <Navbar.Brand href="#home">
        <Image className='logo' src="/assets/img/00mecanotubo.png"  />
      </Navbar.Brand>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto"  > 
        
       { ListNav.map( navItem => (
            <Link to={navItem.link} className='nonDecoration'>      
            <ButtonToolbar>
              <SplitButton
                title={navItem.title}
                variant='light'
                id='dropdown-split-variants-light'
                key='light1'
                drop='down'
                size='2'
                menuRole= 'menu'
              >
                  {navItem.list} 
              </SplitButton> 
            </ButtonToolbar>
          </Link>
        )
       )}
       
        <ButtonToolbar>  
          { IconNav.map( (iconItem, idx) => (
              <OverlayTrigger
                key = {idx}
                placement='button'
                overlay={
                  <Tooltip id='tooltip-button'>
                    {iconItem.tooltip}
                  </Tooltip>
                }
            >
              <Button variant="ligth"> 
                <Link to={iconItem.link} className="text-secondary" >
                  <FontAwesomeIcon icon={iconItem.icon} size="lg"  /> 
                </Link>
              </Button>
              
            </OverlayTrigger>
          ))}
        </ButtonToolbar>
        
        </Nav>
      </Navbar.Collapse>
    </Navbar>

    <Flags/>
  </div>

  )
}

