import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

// import {manto} from  '../../data/serviceDetailMoldaje'
import {encofrado} from  '../../data/services'


const Manto = function(){
 
  
  return(
    <div>
         {/* <Links data = {encofrado}/>
         <General information={manto} pais={'Chile.'}/> */}
    </div>
 
    
  )
  
}

export default Manto
