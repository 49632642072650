import React from 'react'
import  Links from '../Links'
import General from './GeneralServiceGeo'



import {geotecnia} from  '../../data/services'
import {descabezado} from '../../data/serviceDetailGeotecnia'


const Descabezado = function(){
  return(
    
    <div>
      <Links data = {geotecnia}/>
      <General information={descabezado} pais={'Chile.'}/>
    </div>    
  )
  
}

export default Descabezado
