export const box = {
  title:'Box',
  subtitle:'KMC-100',
  description:'Equipo utilizado a profundidad media y en todo tipo de suelos.',
  img:'./assets/img/entibaciones/BOX/KMC-100_BOX-(BASE)-ISOMETRICO.png',
  img2:'./assets/img/entibaciones/BOX/KMC-100_BOX-(BASE)-FRONTAL.png',
  img3:'./assets/img/entibaciones/BOX/KMC-100_BOX-(BASE+EXTENSION)-ISOMETRICO.png',
  img4:'./assets/img/entibaciones/BOX/KMC-100_BOX-(BASE+EXTENSION)-FRONTAL.png',
 
  table1:[
    {name:'Profundidad Entibada', data:'4,50 mt.'},
    {name:'Ancho Máximo', data:'4,00 mt.'},
  ],
  table2Headers:[
    {header:'Box KMC100', hdim:'Dimensiones', hpeso:"Peso", hpesoCajon:'Peso Cajón'},
  ],
  
  table2:[
    {title: 'Paneles', dim:'3,50 x 2,40 mt.', peso:'799(Kg)', pesoc:"1.860(Kg)"},
    {title: 'Paneles', dim:'4,00 x 2,40 mt.', peso:'887(Kg)', pesoc:"2.036(Kg)"},
    {title: 'Extensiones', dim:'4,00 x 2,40 mt.', peso:'567(Kg)', pesoc:"1.299(Kg)"},
    {title: 'Extensiones', dim:'4,00 x 2,40 mt.', peso:'633(Kg)', pesoc:"1.430(Kg)"},
  ]
}

export const miniBox = {
  title:'Mini',
  subtitle:'BOX',
  description:'MiniBOX Equipo utilizado a poca profundidad y en suelos arenosos.',
  img:'./assets/img/entibaciones/MINI_BOX/MINI_BOX_BASE-ISOMETRICO.png',
  img2:'./assets/img/entibaciones/MINI_BOX/MINI_BOX_CORTE_(BASE).png',
  img3:'./assets/img/entibaciones/MINI_BOX/MINI_BOX_BASE+EXTENSION_ISOMETRICO.png',
  img4:'./assets/img/entibaciones/MINI_BOX/MINI_BOX_CORTE_(BASE+REALZA).png',

  table1:[
    {name:'Profundidad Entibada', data:'3,00 mt.'},
    {name:'Ancho Máximo', data:'1,50 mt.'},
  ],
  table2Headers:[
    {header:'Mini Box', hdim:'Dimensiones', hpeso:"Peso", hpesoCajon:'Peso Cajón'},
  ],
  
  table2:[
    {title: 'Paneles', dim:'3,00 x 2,00 mt.	', peso:'356(Kg)', pesoc:"780(Kg)"},
    {title: 'Extensiones', dim:'3,00 x 1,00 mt.	', peso:'196(Kg)', pesoc:"424(Kg)"},
  ]
}

export const kmgd = {
  title:'Guía Doble',
  subtitle:'KMGD',
  description:'Equipo utilizado a profundidad media y especialmente en suelos con presencia de napas.',
  img:'./assets/img/entibaciones/GUIA_DOBLE/GUIA_DOBLE_STD-ISOEMETRICO.png',
  img2:'./assets/img/entibaciones/GUIA_DOBLE/GUIA_DOBLE_STD-FRONTAL.png',
  img3:'./assets/img/entibaciones/GUIA_DOBLE/GUIA_DOBLE_COMBI-ISOMETRICO.png',
  img4:'./assets/img/entibaciones/GUIA_DOBLE/GUIA_DOBLE_COMBI-FRONTAL.png',

  table1:[
    {name:'Profundidad Entibada', data:'5,50 mt.'},
    {name:'Ancho Máximo', data:'4,00 mt.'},
  ],
  table2Headers:[
    {header:' Guia Doble KMGD', hdim:'Dimensiones', hpeso:"Peso", hpesoCajon:''},
  ],
  
  table2:[
    {title: 'Paneles', dim:'4,00 x 2,40 mt. 	', peso:'1.034(Kg)', pesoc:""},
    {title: 'Paneles', dim:'3,50 x 2,40 mt. 	', peso:'792(Kg)', pesoc:""},

    {title: 'Extensiones', dim:'4,00 x 1,30 mt.', peso:'649(Kg)', pesoc:""},
    {title: 'Extensiones', dim:'3,50 x 1,30 mt.', peso:'500(Kg)', pesoc:""},
  ]
}

export const kmgddp = {
  title:'Guía Doble Paralelo',
  subtitle:'KMGDDP',
  description:'Equipo utilizado a mayor profundidad y especialmente en suelos con presencia de napas y para la construcción de cámaras. Este equipo permite una altura libre sin riostras de hasta 4 mt.',
  img:'./assets/img/entibaciones/GD_PARALELO/GD_PARALELO_ISOMETRICO.png',
  img2:'./assets/img/entibaciones/GD_PARALELO/GD_PARALELO_FRONTAL.png',
  img3:'./assets/img/entibaciones/GD_PARALELO/GD_PARALELO_PLANTA.png',


  table1:[
    {name:'Profundidad Entibada', data:'6,10 mt.'},
    {name:'Ancho Máximo', data:'6,00 mt.'},
  ],
  table2Headers:[
    {header:' Guia Doble KMGDDP', hdim:'Dimensiones', hpeso:"Peso", hpesoCajon:''},
  ],
  
  table2:[
    {title: 'Paneles', dim:'4,00 x 2,40 mt. 	', peso:'1.034(Kg)', pesoc:""},
    {title: 'Paneles', dim:'3,50 x 2,40 mt. 	', peso:'792(Kg)', pesoc:""},

    {title: 'Extensiones', dim:'4,00 x 1,30 mt.', peso:'649(Kg)', pesoc:""},
    {title: 'Extensiones', dim:'3,50 x 1,30 mt.', peso:'500(Kg)', pesoc:""},
  ]
}

export const dobleEsquina = {
  title:'Guía Doble',
  subtitle:'ESQUINA',
  description:'Equipo utilizado a profundidad media y especialmente en suelos con presencia de napas y para la construcción de cámaras',
  img:'./assets/img/entibaciones/GUIA_ESQUINA/GUIA_ESQUINEROS_STD-ISOMETRICO.png',
  img2:'./assets/img/entibaciones/GUIA_ESQUINA/GUIA_ESQUINEROS_STD-FRONTAL.png',
  img3:'./assets/img/entibaciones/GUIA_ESQUINA/GUIA_ESQUINEROS_STD-PLANTA.png',


  table1:[
    {name:'Profundidad Entibada', data:'4,50 mt.'},
    {name:'Ancho Máximo', data:'4,00 mt.'},
  ],
  table2Headers:[
    {header:' Guia Doble Esquina', hdim:'Dimensiones', hpeso:"Peso", hpesoCajon:''},
  ],
  
  table2:[
    {title: 'Paneles', dim:'4,00 x 2,40 mt. 	', peso:'1.034(Kg)', pesoc:""},
    {title: 'Paneles', dim:'3,50 x 2,40 mt. 	', peso:'792(Kg)', pesoc:""},

    {title: 'Extensiones', dim:'4,00 x 1,30 mt.', peso:'649(Kg)', pesoc:""},
    {title: 'Extensiones', dim:'3,50 x 1,30 mt.', peso:'500(Kg)', pesoc:""},
  ]
}
