
export const hincado ={
  title:'Hincado y Retiro de Tablestacas',
  subtitle:'GEOTECNIA',
  description:'Arriendo de Tablestacas: Hoesch 1805 | AZ 26-700 | KL-III | Hincado y retiro de Tablestacas',
  services:[
    {
      name: 'Arriendo de Tablestacas Hoesch 1805 (Largos de 12 a 19mt, verificar disponibilidad)',
      img: './assets/img/geotecnia/gh-1805-01.jpg',
      tableHeaders:[
        {header:'MODELO', detail:'HOESCH 1805'},
      ],
      
      table:[
         {title: 'Ancho Perfil', dim:'1.150 mm' },
         {title: 'Alto Pared', dim:'350 mm' },
         {title: 'Peso/ m2', dim:'125 kg/m2' },
         {title: 'Peso/m', dim:'144 kg/m' },
         {title: 'Módulo Resistente', dim:'1800 cm3/m de pared' },
         {title: 'Área de la sección', dim:'183 cm2' },
         {title: 'Espesor', dim:'9,9 -10,8 cm' },
         {title: 'Tipo', dim:'Machihembrada' },
         {title: 'Calidad del Acero', dim:'S 355 GP' },
      ]
    },
    {
      name: 'Arriendo de Tablestacas AZ 26-700 (Largos de 12 a 19mt, verificar disponibilidad)',
      img: './assets/img/geotecnia/gh-700-02.jpg',
      tableHeaders:[
        {header:'MODELO', detail:'AZ 26-700'},
      ],
      
      table:[
         {title: 'Ancho Perfil', dim:'1.400 mm' },
         {title: 'Alto Pared', dim:'459 mm' },
         {title: 'Peso/ m2', dim:'138,5 kg/m2' },
         {title: 'Peso/m', dim:'193,9 kg/m' },
         {title: 'Módulo Resistente', dim:'2600 cm3/m de pared' },
         {title: 'Área de la sección', dim:'247 cm2' },
         {title: 'Espesor', dim:'10 - 13,5 mm' },
         {title: 'Tipo', dim:'Machihembrada' },
         {title: 'Calidad del Acero', dim:'S460 AP' },
      ]
    },
    {
      name: 'Arriendo de Tablestacas KL-III (Largos de 6mt, verificar disponibilidad)',
      img: './assets/img/geotecnia/gh-kl-03.jpg',
      tableHeaders:[
        {header:'MODELO', detail:'KL III'},
      ],
      
      table:[
         {title: 'Ancho Perfil', dim:'700 mm' },
         {title: 'Alto Pared', dim:'150 mm' },
         {title: 'Peso/ m2', dim:'87,9 kg/m2' },
         {title: 'Peso/m', dim:'61,5 kg/m' },
         {title: 'Módulo Resistente', dim:'540 cm3/m de pared' },
         {title: 'Área de la sección', dim:'78,3 cm2' },
         {title: 'Espesor', dim:'8 mm' },
         {title: 'Tipo', dim:'Machihembrada' },
         {title: 'Calidad del Acero', dim:'S 275 JR' },
      ]
    }, 
  ]
}

export const descabezado ={
  title:'Descabezado de Pilotes',
  subtitle:'GEOTECNIA',
  description:'Abarcamos las más variadas soluciones para ésta área de la ingeniería que se encarga de los suelos como soporte de las edificaciones & obras de infraestructura.',
  services:[
    {
      name:'',
      img: './assets/img/geotecnia/gd-maq-01.jpg',
      img2:'./assets/img/geotecnia/gd-380-02.jpg',
      tableHeaders:[
        {header:'Pile Diameter mm', detail:'Required number of link type 380'},
      ],
      
      table:[
         {title: '700', dim:'9' },
         {title: '800', dim:'10' },
         {title: '900', dim:'11' },
         {title: '1000', dim:'11' },
         {title: '1250', dim:'13' },
         {title: '1500', dim:'15' },
      ]
    },
   
  ]
}



export const fresado ={
  title:'Fresado de Muros & Elementos de Hormigón',
  subtitle:'GEOTECNIA',
  description:'Fresados de Gran Volumen | Fresados de Menor Volumen',
  services:[
    {
      name:'Fresados de Gran Volumen',
      img: './assets/img/geotecnia/gf-maq-01.jpg',
      imgs:''
     
    },
    {
      name:'Fresados de Menor Volumen',
      img:  './assets/img/geotecnia/gp-maq-01.jpg',
      imgs: [
      './assets/img/geotecnia/gp-maq-02.jpg',
      './assets/img/geotecnia/gp-maq-03.jpg',
      './assets/img/geotecnia/gp-maq-04.jpg',
      './assets/img/geotecnia/gp-maq-05.jpg',]
     
    },
  ]
}

export const perforacion ={
  title:'Perforaciones en Hormigón (Verticales y Horizontales)',
  subtitle:'GEOTECNIA',
  description:'Abarcamos las más variadas soluciones para ésta área de la ingeniería que se encarga de los suelos comosoporte de las edificaciones y obras de infraestructura.',
  services:[
    {
      name:'',
      img: './assets/img/geotecnia/gp-maq-01.jpg',
      imgs: [
        './assets/img/geotecnia/gp-maq-02.jpg',
        './assets/img/geotecnia/gp-maq-03.jpg',
        './assets/img/geotecnia/gp-maq-04.jpg',
        './assets/img/geotecnia/gp-maq-05.jpg',]
       
    },
  ]
}


export const demolicion ={
  title:'Demolición de Hormigón - Picado, Fresado & Cizalla',
  subtitle:'GEOTECNIA',
  description:'Abarcamos las más variadas soluciones para ésta área de la ingeniería que se encarga de los suelos comosoporte de las edificaciones y obras de infraestructura.',
  services:[
    {
      name:'',
      img: './assets/img/geotecnia/gp-maq-01.jpg',
      imgs: [
        './assets/img/geotecnia/gp-maq-02.jpg',
        './assets/img/geotecnia/gp-maq-03.jpg',
        './assets/img/geotecnia/gp-maq-04.jpg',
        './assets/img/geotecnia/gp-maq-05.jpg',]
       
    },
  ]
}
