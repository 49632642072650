import React from 'react'
import {Link} from 'react-router-dom'


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


function Links(props){

  let data = props.data

  return(
  
    <Container fluid>
      <Row className='my-3'>
      <Col  className="col-12 d-flex flex-row flex-wrap" >
         {/* <div className=" d-flex flex-row flex-wrap "> */}
        
          <Link to = {data.link}  className="red ">
                {data.title} > 
              </Link>
              
          {data.list.map(ele =>
            <div className="px-2 w-1" key={ele.id}>
              <Link to = {ele.link}   className="text-secondary ">
                {ele.name} |
              </Link>
            </div>
            )}
          {/* </div> */}
      </Col>
      </Row>
    </Container>
  )
}

export default Links


 