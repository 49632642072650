export const entibaciones = {
  title: "Entibaciones",
  link:"/entibaciones",
  list: [
    {id:1, name: 'Mini Box', link:'/minibox'},
    {id:2, name: 'BOX-KMC100', link:'/box'},
    {id:3, name: 'Guía Doble - KMGD', link:'/kmgd'},
    {id:4,name: 'Guía Doble Paralelo - KMGDDP', link:'/kmgddp'},
    {id:5,name: 'Guía Doble Esquina',link:'/dobleesquina'}
  ]
}

export const geotecnia ={
  title: "Geotecnia",
  link:"/geotecnia",
  list: [
  {id:1, name: 'Hincado y retiro de Tablestacas', link:"/hincado"},
  {id:2, name: 'Descabezado de Pilotes', link:"/descabezado"},
  {id:3, name: 'Fresado de muros y elementos de hormigón', link:'/fresado'},
  {id:4, name: 'Perforaciones en hormigón (verticales y horizontales)', link:'/perforaciones'},
  {id:5, name: 'Demolición de hormigón (picado, fresado y cizalla)', link:'/demolicion'},
  ]
}

export const moldaje = {
  title:"Moldaje",
  link:"/moldajes",
  list: [
    { 
      id:1, 
      name: 'Encofrado', 
      link:"/encofrado", 
      detail: [
        {name:"Mecano-Tekko",link:'/tekko'},
        {name: "Mecano-Frami",link:'/frami'},
        {name: "Mecano-Framax",link:'/framax'},
        {name: "Mecano-Play",link:'/play'},
        // {name: "Mecano-Manto",link:'/manto'}
      ]
    },
    {
      id:2, 
      name: 'Soportación', 
      link:"/soportacion",
      detail: [
        {name:"Cimbra Pal",link:'/cimbra'},
        {name: "Alzaprimado",link:'/alzaprimado'},
        {name: "P-35",link:'/p35'},
      ]
    },
    {
      id:3, 
      name:'Soluciones Especiales', 
      link:'/soluciones',
      detail:[
        {name:"Carro Enfierradura",link:'/soluciones'},
        {name: "Mecanorolling",link:'/soluciones'},
        {name: "Mecanobox",link:'/soluciones'},
        {name: "Pilares Circulares",link:'/soluciones'},
      ]
    },
   
  ]
}


export const encofrado = {
  title:"Encofrado",
  link:"/encofrado",
  list: [
    { 
      id:1, 
      name:'Mecano-Tekko',
      link:'/tekko',
    },
    {
      id:2, 
      name:'Mecano Frami',
      link:'/frami',
      
    },
    {
      id:3, 
      name:'Mecano-Framax',
      link:'/framax',
    },
    {
      id:4, 
      name:'Mecano-Play',
      link:'/play',
    },
    // {
    //   id:5, 
    //   name:'Mecano-Manto',
    //   link:'/manto',
    // },
  ]
}


export const soportacion = {
  title:"Soportación",
  link:"/soportacion",
  list: [
    { 
      id:1, 
      name:'Cimbra Pal',
      link:'/cimbra',
    },
    {
      id:2, 
      name:'Alzaprimado',
      link:'/alzaprimado',
    },
    {
      id:3, 
      name:'P-35',
      link:'/p35',
      
    },
    
   
  ]
}

export const about = {
  title:'Nosotros',
  link:'/about',
  list:[
    { 
      id:1, 
      name:'Nuestras Obras',
      link:'/nuestrasobras',
    },
    // { 
    //   id:2, 
    //   name:'Clientes',
    //   link:'/clientes',
    // },
    { 
      id:3, 
      name:'Asesorías',
      link:'/asesorias',
    },
    { 
      id:4, 
      name:'Nuestro Equipo',
      link:'/team',
    },
    { 
      id:5, 
      name:'Oficinas',
      link:'/office',
    },
  ]
  
}


export const fichas = {
  title:'Fichas',
  link:'/fichas',
  list:[
    { 
      id:1, 
      name:'Catálogos',
      link:'/fichas',
    },
    { 
      id:2, 
      name:'Manuales',
      link:'/fichas',
    },
   
  ]
  
}
