import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './style.css'


import {Provider} from 'react-redux'
import {HashRouter} from 'react-router-dom'
import history from './history'
import store from './store'

ReactDOM.render(
    <Provider store={store}>
      <HashRouter history={history}>

         < App />
         
      </HashRouter>
    </Provider>


, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
