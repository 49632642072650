import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {miniBox} from  '../../data/serviceDetailEntibacion'
import {entibaciones} from  '../../data/services'

const MiniBox = function(){
  return(
    <div>
         <Links data = {entibaciones}/>
         <General information={miniBox} pais={'Chile y Perú.'}/>
    </div>
 
    
  )
  
}

export default MiniBox
