import React from 'react'
import {projects} from './../../data/projects'


import General from '../General'
import Gallery from '../Gallery'


function NuestrasObras(){
  
  let title = 'NUESTRAS OBRAS'
  let text = 'Algunas de nuestras obras. '
  let subtitulo = 'Contáctenos para mayor informacion. '
   
  return(

 <div>
    <General title ={title} text = {text} imgStyle = 'jumbotronGeneral imgWork'
            detail = {''} list={''} subtitulo={subtitulo} />
   
      
         <Gallery projects={projects} />
         

    </div>
  )
}

export default NuestrasObras
