import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'



function General(props){
  // console.log(props.list)
 
  return(
    <div>
      
   
    <div className='general'>
    
    <Jumbotron  fluid className={props.imgStyle}  >
        <div className="col-lg-4 col-md-10 box" >
        
          <h1>{props.title} </h1>
          <h3> {props.text} </h3>
    
      </div>
     
    </Jumbotron>
   
    </div>
    
    
    <Container fluid>
      <Row className=' my-3 detail'>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12" >
          <h4 className="text-left red">
              {props.detail.subtitle? props.detail.subtitle : props.subtitulo}
          </h4>
          
          <div className="text-left ">
            <h5>
                {props.detail.parragraph ? props.detail.parragraph : props.detail }
            </h5>
          
          </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <h4 className="text-left mt-3 black">
          {props.subtituloList2 ? props.subtituloList2 : null}
        </h4>
       
         
          <h6> 
          <div className="d-flex flex-row flex-wrap ">
            {props.list}
          </div>
          </h6>
          
          
 

      </div>
            
    
        
      </Row>
      
      </Container>
  
    </div>
 
  )
}

export default General


 