import React from 'react';
import Links from '../Links';
import General from '../GeneralService';

import { tekko } from '../../data/serviceDetailMoldaje';
import { encofrado } from '../../data/services';

const Tekko = function () {
  // console.log(tekko)npm 

  return (
    <div>
      <Links data={encofrado} />
      <General information={tekko} pais={'Chile.'} />
    </div>
  );
};

export default Tekko;
