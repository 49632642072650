import React from 'react'
import {Link} from 'react-router-dom'

import {projects} from './../data/projects'



import ControlledCarousel from './ControlledCarousel' 


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck, faHandshake, faMapPin , faShare} from '@fortawesome/free-solid-svg-icons'
import { faLinode } from '@fortawesome/free-brands-svg-icons';
import ContactForm from './Form/ContactForm'



function Main(){  
  
  let title = 'FILOSOFÍA MECANOTUBO'
  let text = 'Somos una empresa enfocada al apoyo de obras en construcción tanto en edificación como en la obra civil, orientando nuestros servicios principalmente al arriendo y venta de Encofrado, Soportación, Entibaciones y Moldajes Especiales. '
  let text2 = 'Nuestro principal objetivo, es entregar a nuestros clientes un gran servicio, que se sustenta en la rapidez de respuesta, entrega de seguridad en las obras, calidad en sentido amplio y competitividad en precios, fidelizando con nuestros clientes.'
  
const services = [
  {id:1, name: 'Asesoría Profesional' },
  {id:2, name: 'Rapidez a sus consultas' },
  {id:3, name: 'Asesoría en Terreno' },
  {id:4, name: 'Soluciones Especiales' },
]

/// UPDATE and render from Projects //
const projects =['Edificio Marcoleta',
  'Museo de Carabineros',
  'Ferretería O’Higgins',
  'Estanques Aconcagua Foods',
  'Centro de Molienda Puzolana',
  'Mall Plaza Sur',
  'Edificio Consistorial' ,
  'Gimnasio Saint George',
  'Campo Deportivo Juan Gómez Milla']



    const boxes=  services.map(service => {
      let photo = ''
      switch (service.id){
        case 1:
          photo = <FontAwesomeIcon icon={faUserCheck}  />
          break;
        case 2:
            photo =   <FontAwesomeIcon icon={faHandshake} />
            break;
        case 3:
            photo =   <FontAwesomeIcon icon={faMapPin} />
            break;
        case 4:
            photo =   <FontAwesomeIcon icon={faLinode} />
            break;
          default:
              photo =''
        break;
      }
    return (
        <Col className="text-center col-md-3 col-lg-3 col-6 red boxes" key={service.id}> 
          <h1  >{photo} </h1> 
            <h2> {service.name} </h2>
            <h6> MECANOTUBO </h6>
        </Col>
              ) }
    )  


  return(

    <div>
       <ControlledCarousel/>
       <Container fluid>
          <Row className="my-5">
            {boxes}
          </Row>
      </Container>
    
    <div className='general main'>
    <Jumbotron  fluid className='jumbotronGeneral imgMain' >
      <div className="col-lg-5 col-md-10 box" >
          <h2>{title} </h2>
          <h4> {text} </h4>
          <h5> {text2} </h5>
              </div>
    </Jumbotron>
    </div>
    
      
 
    <Jumbotron fluid className='mb-n4' >
        <Container fluid >
          <Row>
          <Col className="col-lg-5 col-xl-5 col-md-12 col-sm-12 col-xs-12 " > 
                <h3 className="red  ">Proyectos</h3> 
        
              <Col  className="col-12" >
                
                <div className="d-flex flex-row flex-wrap "> 
                  {projects.map(project =>(
                    <li key={project} className="p-1 px-3 w-50">{project}</li>
                  ))}
                  <h5>
                    <Link to="/nuestrasobras" className="p-1  px-5 w-50 red ">Ver Más   
                      <FontAwesomeIcon icon={faShare} size="1x" className="pl-2" />
                  </Link>
                  </h5>
                </div>
              </Col>
          </Col>
          <hr className='m2'/>
          <Col className="col-lg-7 col-xl-7 col-md-12 col-sm-12 col-12" > 
              <ContactForm/>
          </Col>
          </Row>
        </Container>
    </Jumbotron>
 
    </div>
   
  )
}

export default Main
