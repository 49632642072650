import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {p35} from  '../../data/serviceDetailMoldaje'
import {soportacion} from  '../../data/services'


const P35 = function(){
 
  
  return(
    <div>
         <Links data = {soportacion}/>
         <General information={p35} pais={'Chile.'}/>
    </div>
 
    
  )
  
}

export default P35
