export const team = [
  {
    id	:	1	,
    name	:"Creatividad",
    description	:"Tenemos la capacidad de generar nuevas soluciones y estamos en constante búsqueda de nuevos servicios que sean valiosos para nuestros clientes.",
    img	:""},
  {
    id	:	2	,
    name	:"Transparencia",
    description	:"Mecanotubo Actúa con sinceridad, lo que nos permite contar con la confianza de nuestra comunidad, logrando la fidelización de nuestros clientes.",
    img	:""},
  {
    id	:	3	,
    name	:"Trabajo en Equipo",
    description	:"Nuestros colaboradores trabajan por un objetivo común, con una estructura organizada que fortalece y nos permite cumplir con las metas propuestas, ayudándonos y apoyándonos en todo proceso organizacional.",
    img	:""},
 
  ]
