import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {play} from  '../../data/serviceDetailMoldaje'
import {encofrado} from  '../../data/services'


const Play = function(){
 
  
  return(
    <div>
         <Links data = {encofrado}/>
         <General information={play} pais={'Chile.'}/>
    </div>
 
    
  )
  
}

export default Play
