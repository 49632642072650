import React from 'react'
import  Links from '../Links'
import Disponibilidad from '../Disponibilidad'


import {cimbra} from  '../../data/serviceDetailMoldaje'
import {soportacion} from  '../../data/services'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Media from 'react-bootstrap/Media'
import Row from 'react-bootstrap/Row'


const Cimbra = function(){
 
  
  return(
    <div>
   <Links data = {soportacion}/>
      
        
<Jumbotron fluid  className="m-0">
  <Container fluid  >
    <h1 className="text-center red">{cimbra.title}</h1>
    <h2 className="text-center">{cimbra.subtitle}</h2>
    <h5 className="text-center">{cimbra.description}</h5>
   
    
      <Media className='p-3  '>
      <img
        width={600}
        height={'100%'}
        className="align-self-top mr-2 ml-5	d-none d-lg-block d-xl-block "
        src={cimbra.img} 
        alt="Generic placeholder"
      />
      
      
      <img
        width={800}
        height={'100%'}
        className="align-self-top mr-2 ml-5	d-none d-lg-block d-xl-block "
        src='./assets/img/moldajes/ms-cimbra02.png'
        alt="Generic placeholder"
      />
         
         
      
       <Row className="">
          <Col className="col-sm-12 col-md-12 d-none d-sm-block d-lg-none">
              <img
              width={'auto'}
              height={'auto'}
              className="mb-5 "
              src={cimbra.img} 
              alt="Generic placeholder"
              />
          </Col>
          
        
          
          
          <Col className="col-sm-12 col-md-12  d-none d-sm-block d-lg-none">
          <img
              width={'auto'}
              height={'auto'}
              className="mb-5 "
              src='./assets/img/moldajes/ms-cimbra02.png'
              alt="Generic placeholder"
              />
          </Col>
          </Row>
    </Media>
        
    
    <br/>
    <Disponibilidad pais={'Chile.'}/>

  </Container>
</Jumbotron>
  
        
        
        
        
        
       
    </div>
 
    
  )
  
}

export default Cimbra
