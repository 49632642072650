import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
// import PropTypes from 'prop-types'
import {
 Main, 
 About, NuestrasObras, 
//  Clientes, 
  Asesoria,Team, Office,
 Entibaciones, Box, MiniBox, Kmgd, Kmgddp, DobleEsquina,
 Moldajes, 
 Encofrado, Tekko, Frami, Framax, Manto, Play,
 Soportacion, 
 Soluciones,
 Geotecnia, Hincado, Fresado, Demolicion, Descabezado, Perforaciones, Cimbra, Alzaprimado, P35, 
 PhotoGallery,
 ContactForm, 
 Fichas
} from './components'
 
/**
 * COMPONENT
 */
class Routes extends Component {


  render() {

    return (
      <Switch>
        {/* Routes placed here are available to all visitors */}
        <Route exact path="/" component={Main} />
        <Route path="/home" component={Main} />
        
        
                
       {/* ABOUT */}
        
        <Route path="/about" component={About} />      
        <Route path="/nuestrasobras" component={NuestrasObras} />
        {/* <Route path="/clientes" component={Clientes} />  */}
        <Route path="/asesorias" component={Asesoria} /> 
        <Route path="/team" component={Team} /> 
        <Route path="/office" component={Office} /> 
        
        
       {/* ENTIBACIONES */}
        
        <Route path="/entibaciones" component={Entibaciones} /> 
        <Route path="/box" component={Box} /> 
        <Route path="/minibox" component={MiniBox} /> 
        <Route path="/kmgd" component={Kmgd} /> 
        <Route path="/kmgddp" component={Kmgddp} /> 
        <Route path="/dobleesquina" component={DobleEsquina} /> 
        
      {/* MOLDAJES*/}

        <Route path="/moldajes" component={Moldajes} /> 
        
        <Route path="/encofrado" component={Encofrado} /> 
        <Route path="/tekko" component={Tekko} /> 
        <Route path="/frami" component={Frami} /> 
        <Route path="/framax" component={Framax} /> 
        <Route path="/manto" component={Manto} /> 
        <Route path="/play" component={Play} /> 
        
        <Route path="/soportacion" component={Soportacion} /> 
        <Route path="/cimbra" component={Cimbra} />
        <Route path="/p35" component={P35} />
        <Route path="/alzaprimado" component={Alzaprimado} /> 
        
        
        
        <Route path="/soluciones" component={Soluciones} /> 
      {/* GEOTECNIA*/}

        <Route path="/geotecnia" component={Geotecnia} /> 

        <Route path="/hincado" component={Hincado} /> 
        <Route path="/fresado" component={Fresado} /> 
        <Route path="/demolicion" component={Demolicion} /> 
        <Route path="/descabezado" component={Descabezado} /> 
        <Route path="/perforaciones" component={Perforaciones} /> 
        
       {/* photo gallery*/}

       <Route exact path="/gallery" component={PhotoGallery} />  
       
       
         {/* Contact Form*/}

         <Route exact path="/contact" component={ContactForm} />  
         
      {/* Fichas*/}

            <Route exact path="/fichas" component={Fichas} />  
      </Switch>
    )
  }
}

export default Routes
