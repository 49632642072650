import React from 'react'
import Disponibilidad from './Disponibilidad'


import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Media from 'react-bootstrap/Media'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTh} from '@fortawesome/free-solid-svg-icons'

function General(props){
  
  let information = props.information
  
  
  let table1 =  information.table1 ?  
      <Col className="col-xlg-7 col-lg-8 col-md-12 col-sm-12 col-xs-12 mx-auto text-center">
     
      <Table className='table-light'  >
        <thead>
          {information.table1.map(ele =>
             <tr>
             <th className="redDark bg-white ">{ele.name}</th>
             <th>{ele.data}</th>
           </tr>
            )}
        </thead>
      </Table>
      </Col> : null 

    let table2 =    <Col className="col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mx-auto text-center">
            <Table responsive >
            <thead>
              {information.table2Headers.map(ele =>
                <tr>
                <th> <FontAwesomeIcon icon={faTh} size="sm" className="redLink" /> {ele.header}</th>
                <th>{ele.hdim}</th>
                <th>{ele.hpeso}</th>
                <th>{ele.hpesoCajon}</th>
              </tr>
                )}
            </thead>
            <tbody>
              {information.table2.map(ele =>
                <tr>
                <td>{ele.title}</td>
                <td>{ele.dim}</td>
                <td>{ele.peso}</td>
                <td>{ele.pesoc}</td>
              </tr>
                )}
            </tbody>
            </Table> </Col>
  
  return(
  <Jumbotron fluid  className="m-0 ">
  <Container fluid  >
    <h1 className="text-center red">{information.title}</h1>
    <h2 className="text-center">{information.subtitle}</h2>
    <h5 className="text-center">{information.description}</h5>
   
    {/* FOR DESKTOP */}
    <div className="d-none d-lg-block d-xl-block">
      
      <Media>
      <Row className="mx-auto">

      <img
       
        height={'100%'}
        className="align-self-top mr-2 ml-2	d-none d-lg-block d-xl-block "
        src={information.img} 
        alt="Generic placeholder"
      />
      { information.img2 ?
        <img
        // width={450}
        height={'100%'}
        className="align-self-top mr-2 ml-2	d-none d-lg-block d-xl-block "
        src={information.img2} 
        alt="Generic placeholder"
        /> : null 
      }
      </Row>
      <Row className="mx-auto">

    
    { information.img3 ?
        <img
        // width={450}
        height={'100%'}
        className="align-self-top mr-2 ml-2	d-none d-lg-block d-xl-block "
        src={information.img3} 
        alt="Generic placeholder"
        /> : null 
      }
       { information.img4?
        <img
        // width={450}
        height={'100%'}
        className="align-self-top mr-2 ml-2	d-none d-lg-block d-xl-block "
        src={information.img4} 
        alt="Generic placeholder"
        /> : null 
      }
      </Row>
         
      
       <Row className="mx-auto">
       {table1}
       {table2}
          


          </Row>
    </Media>
        
    </div>
    
    {/* FOR MOBILE */}
    <div className="d-block d-none d-sm-block d-lg-none d-sm-none">
        <Col className="col-sm-12 col-md-12  text-center ">
              <img
              width={250}
              height={'auto'}
              className=""
              src={information.img} 
              alt="Generic placeholder"
              />
          
              
              { information.img2 ?
           <img
           width={250}
           height={'auto'}
           className=""
           src={information.img2} 
           alt="Generic placeholder"
           />: null 
      }
      
      { information.img3 ?
           <img
           width={250}
           height={'auto'}
           className=""
           src={information.img3} 
           alt="Generic placeholder"
           />: null 
      }
      
      { information.img4 ?
           <img
           width={250}
           height={'auto'}
           className=""
           src={information.img4} 
           alt="Generic placeholder"
           />: null 
      }
      
          </Col>
          
        <Col className='my-3'>
        {table1}
        
        </Col>
        
        {table2}
      
    </div>
  
    
    
    
    <br/>
    <Disponibilidad pais={props.pais}/>

  </Container>
</Jumbotron>
  )
  
}

export default General
