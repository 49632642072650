import React from 'react' 
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCamera  } from '@fortawesome/free-solid-svg-icons'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


function Thanks(){
  return(
    <Row >
      <br/>

    <Col className="text-center red" > 
    <h4 className="m-5">Gracias por su consulta, nos podremos en contacto a la brevedad.</h4> 
    <h5 className="text-secondary"> Lo invitamos a visitar nuestra Galeria de Proyectos</h5>
    <Link   to="/gallery"  className="red mt-2 mx-2">
        <FontAwesomeIcon icon={faCamera} size="4x"  className="secondary"/> 
    </Link>
               
  </Col>
    
</Row>
  )
}

export default Thanks
