import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {alzaprimado} from  '../../data/serviceDetailMoldaje'
import {soportacion} from  '../../data/services'


const Alzaprimado = function(){
 
  
  return(
    <div>
         <Links data = {soportacion}/>
         <General information={alzaprimado} pais={'Chile.'}/>
    </div>
 
    
  )
  
}

export default Alzaprimado
