import React from 'react'
import  Links from '../Links'
import General from '../GeneralService'

import {entibaciones} from  '../../data/services'
import {dobleEsquina} from  '../../data/serviceDetailEntibacion'

const DobleEsquina = function(){
  return(
    
    <div>
      <Links data = {entibaciones}/>
      <General information={dobleEsquina} pais={'Chile y Perú.'}/>
    </div>
    
  )
  
}

export default DobleEsquina
