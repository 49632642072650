export const encofrado = 
[{name:'Mecano-Tekko',
  link:'/tekko',
  description:'Paneles manuportables, con una gran capacidad de adaptación a formas variables. Para trabajar sobre pequeñas superficies como fundaciones, sobrecimientos y laterales de viga para uso en obras de edificación y obras industriales.',
  img:'./assets/img/moldajes/m-tekko.jpg',
},
{ name:'Mecano Frami',
  link:'/frami',
  description:'Mecano-Frami es un elaborado sistema de encofrados de pequeños tableros con retícula lógica del sistema, diseñado especialmente para encofrar de manera rápida y económica cimientos y pequeñas superficies como muros, laterales de viga, muros curvos.',
  img:'./assets/img/moldajes/m-frami.jpg',
},
{ name:'Mecano-Framax',
  link:'/framax',
  title:'Mecano',
  subtitle:'FRAMAX',
  description:'Mecano-Framax es el sistema de encofrado marco que con unos pocos elementos consigue una retícula consecuente de 15 cm. ya se utilice en sentido vertical u horizontal. ',
  img:'./assets/img/moldajes/m-framax.jpg',
},
{ name:'Mecano-Play',
  link:'/play',
  title:'Mecano',
  subtitle:'PLAY',
  description:'Mecano-play es el encofrado modular para muro de hormigón visto, diseñado para su manipulación con grúa, con tan sólo 2 tirantes en altura para una superficie de encofrado de 3 m2.',
  img:'./assets/img/moldajes/m-mplay.jpg',
},
// { name:'Mecano-Manto',
//   link:'/manto',
//   title:'Mecano',
//   subtitle:'MANTO',
//   description:'Este es un sistema de encofrado universal para toda aplicación, y particularmente para proyectos habitacionales e industriales de gran escala. Permite reducir los ciclos de trabajo en obra, ya que el encofrado.',
//   img:'./assets/img/moldajes/m-manto.jpg',
// },
]


export const tekko = 
{ title:'Mecano',
  subtitle:'TEKKO',
  description:'Paneles manuportables, con una gran capacidad de adaptación a formas variables. Para trabajar sobre pequeñas superficies como fundaciones, sobrecimientos y laterales de viga para uso en obras de edificación y obras industriales.',
  img:'./assets/img/moldajes/m-tekko01.png',
  table1:[],
  table2Headers:[
    {header:'Mecano Tekko', hdim:'Peso'},
  ],
  table2:[
  {title: 'Panel Tekko 0,30 x 0,90 mt', dim:'11,0 kg.' },
   {title: 'Panel Tekko 0,45 x 0,90 mt.', dim:'16,2 kg.' },
   {title: 'Panel Tekko 0,60 x 0,90 mt.', dim:'17,8 kg.' },
   {title: 'Panel Tekko 0,90 x 0,90 mt.', dim:'25,5 kg.' },
   {title: 'Panel Tekko 0,30 x 1,20 mt.', dim:'14,7 kg.' },
   {title: 'Panel Tekko 0,45 x 1,20 mt.', dim:'21,6 kg.' },
   {title: 'Panel Tekko 0,60 x 1,20 mt.', dim:'23,7 kg.' },
   {title: 'Panel Tekko 0,90 x 1,20 mt.', dim:'32,7 kg.' },
  ]
}


export const frami = { name:'Mecano Frami',
link:'/frami',
title:'Mecano',
subtitle:'FRAMI',
description:'Mecano-Frami es un elaborado sistema de encofrados de pequeños tableros con retícula lógica del sistema, diseñado especialmente para encofrar de manera rápida y económica cimientos y pequeñas superficies como muros, laterales de viga, muros curvos.',
img:'./assets/img/moldajes/m-frami01.jpg',
table1:[],
table2Headers:[
  {header:'Mecano Frami', hdim:'Peso'},
],
table2:[
   {title: 'Elemento de arco Frami 0,20 x 1,50 mt.', dim:'36,0 kg.' },
   {title: 'Elemento de arco Frami 0,25 x 1,50 mt.', dim:'38,0 kg.' },
   {title: 'Elemento marco Frami 0,30 x 1,50 mt.', dim:'23,0 kg.' },
   {title: 'Elemento marco Frami 0,45 x 1,50 mt.', dim:'28,2 kg.' },
   {title: 'Elemento marco Frami 0,60 x 1,50 mt.', dim:'35,0 kg.' },
   {title: 'Elemento marco Frami 0,75 x 1,50 mt.', dim:'39,7 kg.' },
   {title: 'Elemento marco Frami 0,90 x 1,50 mt.', dim:'43,3 kg.' },
   {title: 'Elemento marco Frami 0,30 x 2,70 mt.', dim:'39,0 kg.' },
   {title: 'Elemento marco Frami 0,45 x 2,70 mt.', dim:'48,0 kg.' },
   {title: 'Elemento marco Frami 0,60 x 2,70 mt.', dim:'57,8 kg.' },
   {title: 'Elemento marco Frami 0,75 x 2,70 mt.', dim:'66,4 kg.' },
   {title: 'Elemento marco Frami 0,90 x 2,70 mt.', dim:'75,0 kg.' },
]
}

export const framax = { 
  title:'Mecano',
  subtitle:'FRAMAX',
  description:'Mecano-Framax es el sistema de encofrado marco que con unos pocos elementos consigue una retícula consecuente de 15 cm. ya se utilice en sentido vertical u horizontal. ',
  img:'./assets/img/moldajes/m-framax01.png',
  table1:[],
table2Headers:[
  {header:'Mecano Framax', hdim:'Peso'},
],  
  table2:[
   {title: 'Elemento marco Framax 0,30 X 1,35 mt.', dim:'35,0 kg.' },
   {title: 'Elemento marco Framax 0,45 X 1,35 mt.', dim:'32,8 kg.' },
   {title: 'Elemento marco Framax 0,60 X 1,35 mt.', dim:'52,6 kg.' },
   {title: 'Elemento marco Framax 0,90 X 1,35 mt.', dim:'71,2 kg.' },
   {title: 'Elemento marco Framax 0,30 X 2,70 mt.', dim:'66,0 kg.' },
   {title: 'Elemento marco Framax 0,45 X 2,70 mt.', dim:'81,3 kg.' },
   {title: 'Elemento marco Framax 0,60 X 2,70 mt.', dim:'97,0 kg.' },
   {title: 'Elemento marco Framax 0,90 X 2,70 mt.', dim:'128,8 kg.' },
   {title: 'Elemento marco Framax 2,40 X 2,70 mt.', dim:'357,0 kg' },
   {title: 'Elemento marco Framax 1,00 X 3,00 mt.', dim:'225,0 kg.' },
  ]
}

export const play ={ 
  title:'Mecano',
  subtitle:'PLAY',
  description:'Mecano-play es el encofrado modular para muro de hormigón visto, diseñado para su manipulación con grúa, con tan sólo 2 tirantes en altura para una superficie de encofrado de 3 m2.',
  img:'./assets/img/moldajes/m-play01.png',
  table1:[],
  table2Headers:[
    {header:'Mecano Play', hdim:'Peso'},
  ],
  
  table2:[
  {title: 'Panel Alisply 0,30 x 1,00 mt.', dim:'26,0 kg.' },
   {title: 'Panel Alisply 0,40 x 1,00 mt.', dim:'30,5 kg.' },
   {title: 'Panel Alisply 0,50 x 1,00 mt.', dim:'35,0 kg.' },
   {title: 'Panel Alisply 0,60 x 1,00 mt.', dim:'39,0 kg.' },
   {title: 'Panel Alisply 0,80 x 1,00 mt.', dim:'45,0 kg.' },
   {title: 'Panel Alisply 1,00 x 1,00 mt.', dim:'51,0 kg.' },
   {title: 'Panel Alisply 2,00 x 1,00 mt.', dim:'100,0 kg.' },
   {title: 'Panel Alisply 0,30 x 3,00 mt.', dim:'73,0 kg.' },
   {title: 'Panel Alisply 0,40 x 3,00 mt.', dim:'86,0 kg.' },
   {title: 'Panel Alisply 0,50 x 3,00 mt.', dim:'96,0 kg.' },
   {title: 'Panel Alisply 0,60 x 3,00 mt.', dim:'112,0 kg.' },
   {title: 'Panel Alisply 0,80 x 3,00 mt.', dim:'130,0 kg.' },
   {title: 'Panel Alisply 1,00 x 3,00 mt.', dim:'149,0 kg.' },
   {title: 'Panel Alisply 2,00 x 3,00 mt.', dim:'316,0 kg.' },
  ]
}

// export const manto = { 
//   title:'Mecano',
//   subtitle:'MANTO',
//   description:'Este es un sistema de encofrado universal para toda aplicación, y particularmente para proyectos habitacionales e industriales de gran escala. Permite reducir los ciclos de trabajo en obra, ya que el encofrado.',
//   img:'./assets/img/moldajes/m-manto01.png',
//   table1:[],
//   table2Headers:[
//     {header:'Mecano Manto', hdim:'Peso'},
//   ],
//   table2:[
//      {title: 'Panel Manto 0,60 x 1,20 mt.', dim:'47,4 kg.' },
//      {title: 'Panel Manto 0,70 x 1,20 mt.', dim:'52,2 kg.' },
//      {title: 'Panel Manto 0,90 x 1,20 mt.', dim:'61,2 kg.' },
//      {title: 'Panel Manto 1,20 x 1,20 mt.', dim:'75,9 kg.' },
//      {title: 'Panel Manto 2,40 x 1,20 mt.', dim:'140,3 kg.' },
//      {title: 'Panel Manto 0,60 x 2,70 mt.', dim:'97,8 kg.' },
//      {title: 'Panel Manto 0,70 x 2,70 mt.', dim:'107,5 kg.' },
//      {title: 'Panel Manto 0,90 x 2,70 mt.', dim:'126,9 kg.' },
//      {title: 'Panel Manto 1,20 x 2,70 mt.', dim:'155,3 kg.' },
//      {title: 'Panel Manto 2,40 x 2,70 mt.', dim:'308,5 kg.' },
//      {title: 'Panel Manto 0,60 x 3,00 mt.', dim:'108,0 kg.' },
//      {title: 'Panel Manto 0,70 x 3,00 mt.', dim:'118,5 kg.' },
//      {title: 'Panel Manto 0,90 x 3,00 mt.', dim:'139,6 kg.' },
//      {title: 'Panel Manto 1,20 x 3,00 mt.', dim:'170,7 kg.' },
//      {title: 'Panel Manto 2,40 x 3,00 mt.', dim:'344,1 kg.' },
//   ]
// }



export const soportacion = 
[{name:'Cimbra Pal',
  link:'/cimbra',
  description:'Estructura formada por elementos triangulares indeformables y ensamblables unos en otros para soporte de losa y vigas.La cimbra PAL de base cuadrada o triangular, permite alcanzar alturas hasta de 30 mt.',
  img:'./assets/img/moldajes/ms-cimbra.jpg',
},
{ name:'Alzaprimado',
  link:'/alzaprimado',
  title:'Soportacion',
  subtitle:'ALZAPRIMADO',
  description:'Sistema de soportación de losa de poco espesor utilizado generalmente en obras de edificación habitacional. Éste sistema permite un rápido montaje compuesto por trípode, alzaprima, cabezal, envigado y fenólico. Soporta carga de hasta 1,20 toneladas.',
  img:'./assets/img/moldajes/ms-alzaprimado.jpg',
},
{ name:'P-35',
  link:'/p35',
  title:'Soportacion',
  subtitle:'P-35',
  description:'Sistema de soportación de losa cuya utilización principal corresponde a la confección de pórticos y plataformas intermedias. Posee una gran capacidad de carga que alcanza las 35 toneladas.',
  img:'./assets/img/moldajes/ms-p35.jpg',
},
]



export const cimbra = 
{ title:'Soportación',
  subtitle:'CIMBRA PAL',
  description:'Estructura formada por elementos triangulares indeformables y ensamblables unos en otros para soporte de losa y vigas.La cimbra PAL de base cuadrada o triangular, permite alcanzar alturas hasta de 30 mt., con una capacidad de carga de 6 toneladas por pata. Esta estructura portante y plataforma de trabajo es de instalación muy rápida, no requiere herramienta alguna. En los sistemas de soporte de losa se incluye torre de carga, envigado principal y secundario.',
  img:'./assets/img/moldajes/ms-cimbra01.jpg',
  table1:[],
  table2Headers:[],
  table2:[]
}

export const alzaprimado = {
  title:'Soportacion',
  subtitle:'ALZAPRIMADO',
  description:' Soporta carga de hasta 1,20 toneladas aproximadamente y su regulación va desde 1,75 a 5,50 mt. de altura.',
  img:'./assets/img/moldajes/ms-alzaprimado.jpg',
  table1:[],
  table2Headers:[
    {header:'Sistema de soportación de losa de poco espesor utilizado generalmente en obras de edificación habitacional. Éste sistema permite un rápido montaje compuesto por trípode, alzaprima, cabezal, envigado y fenólico. Soporta carga de hasta 1,20 toneladas aproximadamente y su regulación va desde 1,75 a 5,50 mt. de altura.'},
  ],
  table2:[]
}

export const p35 ={
  title:'Soportacion',
  subtitle:'P-35',
  description:'Posee una gran capacidad de carga que alcanza las 35 toneladas por puntal permitiendo alcanzar alturas de hasta 20 mt.',
  img:'./assets/img/moldajes/ms-p35_01.png',
  table1:[],
  table2Headers:[
    {header:'Sistema de soportación de losa cuya utilización principal corresponde a la confección de pórticos y plataformas intermedias. Posee una gran capacidad de carga que alcanza las 35 toneladas por puntal permitiendo alcanzar alturas de hasta 20 mt.'},
  ],
  table2:[]
}


export const soluciones = 

[{name:'Carro Enfierradura',
  link:'',
  title:'Soluciones Especiales',
  subtitle:'Carro Enfierradura',
  description:'Estructuras utilizadas como plataforma de trabajo para la colocación de enfierradura y shotcret en túneles, zanjas y cajones.',
  img:'./assets/img/moldajes/msp-carro.jpg',
  table1:[{}],
  table2Headers:[
   
  ],
  
  table2:[
  ]
},
{name:'Mecanorolling',
  link:'',
  title:'Soluciones Especiales',
  subtitle:'Mecanorolling',
  description:'Moldaje especialmente diseñado para pilares de medidas específicas.',
  img:'./assets/img/moldajes/msp-mecanorrolling.png',
  table1:[{}],
  table2Headers:[
    
  ],
  
  table2:[
   

  ]
},
{name:'Mecanobox',
  link:'',
  title:'Soluciones Especiales',
  subtitle:'Mecanobox',
  description:'Carros de encofrado para cimbre y descimbre de muros laterales de largo y altura específica.',
  img:'./assets/img/moldajes/msp-mecanobox.png',
  table1:[{}],
  table2Headers:[
  
  ],
  
  table2:[
  ]
},
{name:'Pilares Circulares',
  link:'',
  title:'Soluciones Especiales',
  subtitle:'Pilares Circulares',
  description:'Carros de encofrado para colectores de dimensiones específicas.',
  img:'./assets/img/moldajes/msp-pilares.png',
  table1:[{}],
  table2Headers:[
  
  ],
  
  table2:[
  ]
},
]
