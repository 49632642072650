import React from 'react'

import {fichas} from '../data/fichas'
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileAlt } from '@fortawesome/free-solid-svg-icons'

function Fichas(){
  
  let result = fichas.map(item => {

    const list = item.list.map(el =>
      <a href={el.link} target="_blanket" className="list-group-item list-group-item-action list-group-item-ligth ">
         <FontAwesomeIcon icon={faFileAlt} size={'2x'} className='mr-3 red'/> {el.name}
      </a>
      
    )
    

    
    return (
     <div>
        <div className="mt-3">
        <h2 className="text-center red"> {item.name} </h2>
        <h5 className="text-center ">{item.detail}</h5>
      </div>
      
      <div className="list-group">
            {list}
      </div>
     </div> 
      )}

      )

  
  return(
    <Container className='my-5'>
       
       {result}
    
    </Container>
  )
}

export default Fichas
